import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import { configActions } from "redux/actions";

const LegalChecklist = () => {
  const { propertyLegals } = useSelector(
    (state) => state.getLegalChecklistReducer
  );
  const dispatch = useDispatch();
  const [porpertyLegalsList, setPropertyLegalsList] = useState([]);

  useEffect(() => {
    dispatch(configActions.getPropertyLegals());
  }, []);

  useEffect(() => {
    if (propertyLegals?.length > 0) {
      setPropertyLegalsList(propertyLegals);
    }
  }, [propertyLegals]);

  const handleDelete = (id) => {
    const sure = window.confirm("Are You sure to delete?");
    if (sure) {
      dispatch(configActions.deleteLegalChecklist(id));
    }
  };

  return (
    <Container fluid className=" px-5">
      <Row className="d-flex my-3  py-1 align-items-center rounded">
        <Col xs="12" md="6" className="text-md-left text-center">
          <span className="rz-heading rz-text-18"> Legal Checklist </span>
        </Col>

        <Col xs="12" md="6" className="text-md-right text-center">
          <NavLink
            className="btn  rz-button-primary"
            to={`/admin/config/legal-checklist/${1}`}
          >
            <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add New
          </NavLink>
        </Col>
      </Row>

      <Table responsive>
        <thead className="rz-thead-style">
          <tr>
            <th scope="col"> TYPE </th>
            <th scope="col"> TOTAL NOS. </th>
            {/* <th scope="col"> LAST UPDATED </th> */}
            <th className="text-right" scope="col">
              ACTIONS
            </th>
          </tr>
        </thead>
        <tbody>
          {porpertyLegalsList?.map((propertyLegal) => {
            return (
              <tr className="bg-white" key={propertyLegal.id}>
                <td> {propertyLegal.name} </td>
                <td> {propertyLegal.propertyLegal.length} </td>
                {/* <td> Time (IST) </td> */}
                <td>
                  <div className="d-flex align-items-center justify-content-end">
                    <NavLink
                      to={`/admin/config/legal-checklist/${propertyLegal.id}`}
                    >
                      <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                    </NavLink>
                    <div
                      className="ml-3"
                      onClick={(e) => handleDelete(propertyLegal.id)}
                    >
                      <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default LegalChecklist;
