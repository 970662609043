import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { propertyActions } from 'redux/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import classnames from 'classnames'
import { useMutatePropertyNearByData, useGetPropertyNearPlaceById } from 'hooks/useProperty';
import { useMutatePropertyNearByUpdateData } from 'hooks/useProperty';
import { toast } from 'react-toastify';
import './../../../css/location.css';
import { usePropertyLocationById } from 'hooks/useProperty';
import { propertyService } from 'redux/services';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom'
const containerStyle = {
    width: '100%',
    height: '400px'
};

const Location = ({ stepper, parentStepper, type }) => {
    const dispatch = useDispatch()
    const property = useSelector(state => state.propertyReducer.property);
    const [autocomplete, setAutocomplete] = useState(null);
    const [mapPosition, setMapPosition] = useState();
    const [loader, setLoader] = useState(false);
    const [mapZoom, setMapZoom] = useState(15);

    const LocationSchema = yup.object().shape({
        address: yup.string().required('Address is required field'),
        country: yup.string().required('Country is required field'),
        state: yup.string().required('County is required field'),
        city: yup.string().required('City is required field'),
        // pincode: yup.string().length(6).required('Pincode is required field'),
        lat: yup.string().required('Latitude is required field'),
        lng: yup.string().required('Longitude is required field'),
    })
    const { register, handleSubmit,
        watch,
        reset, setValue, control, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(LocationSchema)
    })
    const fieldNames = ['address', 'country', 'state', 'city', 'lat', 'lng'];
    const watchAllFields = watch(fieldNames);
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== undefined && field !== null && field !== '');
    const { data: propertyLocation, refetch: refetchLocation } = usePropertyLocationById(property?.id);
    useEffect(() => {
        refetchLocation();
    }, [])
    const onSubmit = async (data) => {
        data.property_id = property.id;
        await propertyService.storeAdditionalOneAwait(data)
        await refetchLocation();
    }

    const onLoadAutocomplete = ref => {
        setAutocomplete(ref)
    }

    const onPlacesChanged = () => {
        var place = autocomplete.getPlaces()[0];
        updatePlaceForm(place)
    }
    const { data, refetch } = useGetPropertyNearPlaceById({
        property_id: property?.id
    },
        true
    )

    const { mutate: addNearByData } = useMutatePropertyNearByData();
    const { mutate: updateNearByData } = useMutatePropertyNearByUpdateData();

    const updatePropertyLocation = async () => {
        if (window.confirm('Are you sure you want to update property near By data?')) {
            setLoader(true)
            await addNearByData(
                { property_id: property?.id },
                {
                    onSuccess: async (res) => {
                        refetch();
                        console.log("res", res)
                        setLoader(false)
                    },
                    onError: (error) => {
                        console.log(error)
                        setLoader(false)
                    }
                }
            );
        }
    }
    const updatePlaceForm = (place) => {
        if (place.photos && place.photos[1] !== undefined) {
            setValue('photo', place.photos[1].getUrl({ 'maxWidth': 500, 'maxHeight': 500 }))
        }
        var lat = place.geometry.location.lat(), lng = place.geometry.location.lng();
        var ac = place.address_components;
        setValue('lat', lat)
        setValue('lng', lng)
        setMapPosition({ lat, lng })

        if (ac) {
            for (var i = 0; i < ac.length; i++) {
                var addressType = ac[i].types[0];
                if (addressType == "locality") {
                    setValue('address', ac[i]?.long_name)
                    setValue('city', ac[i]?.long_name);
                }
                if (addressType == "administrative_area_level_2") {
                    setValue('city', ac[i]?.long_name)
                }
                if (addressType == "administrative_area_level_1") {
                    setValue('state', ac[i]?.long_name)
                }
                if (addressType == "postal_code") {
                    setValue('pincode', ac[i]?.long_name)
                }
                if (addressType == "country") {
                    setValue('country', ac[i]?.long_name)
                }
            }
        }
    }
    const [selectedTag, setselectedTag] = useState("School");
    const tags = data?.data?.map((d) => {
        return {
            name: d.name,
            status: d.status
        }
    })
    const [placesArray, setPlacesArray] = useState({});
    const [locationArray, setLocationArray] = useState([]);
    useEffect(() => {
        const plase = data?.data?.filter((d) => d.name == selectedTag)
        if (plase?.length > 0) {
            setPlacesArray(plase[0])
            setLocationArray(plase[0]?.location)
        }
    }, [selectedTag, property, data])

    useEffect(() => {
        if (property) {
            let l = property?.location;
            if (l) {
                setValue('address', l.address)
                setValue('city', l.city)
                setValue('state', l.state)
                setValue('country', l.country)
                setValue('pincode', l.pincode)
                setValue('lat', l.lat)
                setValue('lng', l.lng)
                setMapPosition({ lat: parseFloat(l?.lat), lng: parseFloat(l?.lng) })
            }
        }
    }, [property]);

    const onMarkerChanged = async (e) => {
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const geocoder = new window.google.maps.Geocoder();
        const places = await geocoder.geocode({ location: { lat, lng } });
        updatePlaceForm(places.results[0])
    }
    const onPropertyStatusChange = async (place, e) => {
        delete place?.location
        let data = {
            property_id: localStorage.getItem("property_id"),
            ...place,
            location: locationArray,
            status: e.target.checked,
        };
        setLoader(true)
        await updateNearByData(
            data,
            {
                onSuccess: async (res) => {
                    refetch();
                    toast.success("Location Updated Successfully.")
                    setLoader(false)
                },
                onError: (error) => {
                    toast.error("Something Went Wrong.");
                    setLoader(false)
                }
            }
        );
    };
    const updateCustomeLocation = async (place) => {
        delete place?.location
        let data = {
            property_id: property?.id,
            ...place,
            location: locationArray,
        };
        setLoader(true)
        await updateNearByData(
            data,
            {
                onSuccess: async (res) => {
                    refetch();
                    setLoader(false)
                    toast.success("Location Updated Successfully.")
                },
                onError: (error) => {
                    toast.error("Something Went Wrong.");
                    setLoader(false)
                }
            }
        );
    };
    const onNearByusubLocation = async (place, e) => {
        let tempLocationArray = [...locationArray];
        const index = tempLocationArray?.findIndex((l) => l?.place_id == place?.place_id);
        if (index != -1) {
            tempLocationArray[index].status = e.target.checked
        }
        setLocationArray(tempLocationArray);
    };
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Location</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {/* AIzaSyD5emCxQCEWPj1OcLMaLzWOdBwX7ASdnkg */}
                <LoadScript googleMapsApiKey="AIzaSyAiRzjHfOF7kj0ku_MAVK3Due4CPLh4LKo" libraries={["places"]} >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapPosition}
                        default
                        zoom={mapZoom}
                        options={{
                            types: ['(cities)'],
                            componentRestrictions: { country: "in" }
                        }}
                    >
                        <StandaloneSearchBox
                            onLoad={onLoadAutocomplete}
                            onPlacesChanged={onPlacesChanged}
                        >
                            <input
                                type='text'
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid #cad1d7`,
                                    width: `15rem`,
                                    height: `calc(1.5em + 1.25rem + 2px)`,
                                    padding: `0.625rem 0.75rem`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: "50%",
                                    marginLeft: "-120px",
                                    lineHeight: 1.5,
                                    borderRadius: '0.375rem',
                                    backgroundClip: 'padding-box'
                                }}
                                placeholder='Search Places'
                            />
                        </StandaloneSearchBox>
                        <Marker
                            position={mapPosition}
                            draggable={true}
                            onDragEnd={onMarkerChanged}
                        />
                    </GoogleMap>
                </LoadScript>
                <Row className='mt-4'>
                    <FormGroup tag={Col} md='12'>
                        <Label htmlFor="states">Address <span className='required-star'>*</span></Label>
                        <input
                            id={`address`}
                            {...register(`address`)}
                            className={classnames({ 'is-invalid': errors.address, 'form-control': true })}
                        />
                        {errors.address && (
                            <small className="form-text text-danger">{errors.address.message}</small>
                        )}
                    </FormGroup>
                    <FormGroup tag={Col} md='4'>
                        <Label htmlFor="states">Country <span className='required-star'>*</span></Label>
                        <input
                            id={`country`}
                            {...register(`country`)}
                            className={classnames({ 'is-invalid': errors.country, 'form-control': true })}
                        />
                        {errors.country && (
                            <small className="form-text text-danger">{errors.country.message}</small>
                        )}
                    </FormGroup>
                    <FormGroup tag={Col} md='4'>
                        <Label htmlFor="states">County *</Label>
                        <input
                            id={`states`}
                            {...register(`state`)}
                            className={classnames({ 'is-invalid': errors.state, 'form-control': true })}
                        />
                        {errors.state && (
                            <small className="form-text text-danger">{errors.state.message}</small>
                        )}
                    </FormGroup>
                    <FormGroup tag={Col} md='4'>
                        <Label htmlFor="city">City <span className='required-star'>*</span></Label>
                        <input
                            id={`city`}
                            {...register(`city`)}
                            className={classnames({ 'is-invalid': errors.city, 'form-control': true })}
                        />
                        {errors.city && (
                            <small className="form-text text-danger">{errors.city.message}</small>
                        )}
                    </FormGroup>
                </Row>
                <Row>
                    {/* <FormGroup tag={Col} md='4'>
                        <Label htmlFor="pincode">Pincode *</Label>
                        <input
                            id={`pincode`}
                            {...register(`pincode`)}
                            className={classnames({ 'is-invalid': errors.pincode, 'form-control': true })}
                        />
                        {errors.pincode && (
                            <small className="form-text text-danger">{errors.pincode.message}</small>
                        )}
                    </FormGroup> */}
                    <FormGroup tag={Col} md='4'>
                        <Label htmlFor="lat">Latitude <span className='required-star'>*</span></Label>
                        <input
                            id={`lat`}
                            {...register(`lat`)}
                            className={classnames({ 'is-invalid': errors.lat, 'form-control': true })}
                        />
                        {errors.lat && (
                            <small className="form-text text-danger">{errors.country.message}</small>
                        )}
                    </FormGroup>
                    <FormGroup tag={Col} md='4'>
                        <Label htmlFor="lng">Longitude <span className='required-star'>*</span></Label>
                        <input
                            id={`lng`}
                            {...register(`lng`)}
                            className={classnames({ 'is-invalid': errors.lng, 'form-control': true })}
                        />
                        {errors.lng && (
                            <small className="form-text text-danger">{errors.lng.message}</small>
                        )}
                    </FormGroup>
                </Row>
                <div className='d-flex justify-content-end mt-4'>
                    <Button type='submit'
                        disabled={!allFieldsFilled}
                        className='rz-button-primary'>
                        <span className='align-middle d-sm-inline-block d-none'>Save Location</span>
                    </Button>
                </div>
                {
                    propertyLocation && (
                        <>
                            <hr></hr>
                            <Card className="bg-white shadow border-0 p-4 mt-3">
                                <Fragment>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3 className='mb-0 rz-heading rz-text-18'>Near by this Asset</h3>
                                        <button onClick={() => updatePropertyLocation()} className="btn rz-button-primary"
                                            type="button">
                                            Update Near By
                                        </button>
                                    </div>
                                    <Row className="m-2 d-flex justify-content-between">
                                        <div class="scrollmenu">
                                            {tags?.map((pic, idx) => (
                                                <a
                                                    key={idx}
                                                    onClick={() => setselectedTag(pic?.name)}
                                                    style={{
                                                        backgroundColor: pic?.name == selectedTag ? "#5E72E4" : "white",
                                                        color: pic?.name == selectedTag ? "white" : pic?.status ? "#28a745" : "red",
                                                        borderLeft: (idx == 0) ? "1 px solid black" : "none"
                                                    }}
                                                >
                                                    {pic?.name}
                                                </a>
                                            ))}
                                        </div>
                                    </Row>
                                    {locationArray?.length > 0 &&
                                        <Row className="m-2 py-4 d-flex justify-content-between">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    type="checkbox"
                                                    checked={placesArray?.status}
                                                    onChange={(e) =>
                                                        onPropertyStatusChange(placesArray, e)
                                                    }
                                                    className="custom-control-input"
                                                    id={`property-main`}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={`property-main`}
                                                >
                                                    Do you want to {placesArray?.status ? "Deactive" : "Activate"} near by {selectedTag}?
                                                </label>
                                            </div>
                                            <button onClick={() => updateCustomeLocation(placesArray)} className="btn btn-primary" type="button">
                                                Update Location Selection
                                            </button>
                                        </Row>
                                    }
                                    <Row>
                                        {!loader ?
                                            <div>
                                                {locationArray?.length > 0 ?
                                                    locationArray?.map((place) => (
                                                        <Card className="m-2" key={place?.place_id}>
                                                            <div className="p-2">
                                                                <div className='d-flex justify-content-between'>
                                                                    <h3>{place.name}</h3>
                                                                    <h3>{parseFloat(place?.distance)?.toFixed(2)}{" "}km</h3>
                                                                </div>
                                                                <div className='d-flex justify-content-between'>
                                                                    <p>{place?.vicinity}</p>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            style={{
                                                                                cursor: "pointer"
                                                                            }}
                                                                            type="checkbox"
                                                                            checked={place?.status}
                                                                            onChange={(e) =>
                                                                                onNearByusubLocation(place, e)
                                                                            }
                                                                            className="custom-control-input"
                                                                            id={`property-sub_main-${place?.place_id}`}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor={`property-sub_main-${place?.place_id}`}
                                                                        >

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )) :
                                                    <></>
                                                    // <Col className='d-flex justify-content-center flex-row mt-4'>
                                                    //     <h3>No {selectedTag} Found Near By Property</h3>
                                                    // </Col>
                                                }
                                            </div>
                                            :
                                            <div className='d-flex justify-content-center'>
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </div>
                                        }
                                    </Row>
                                </Fragment >
                            </Card>
                            <div className='d-flex justify-content-between mt-5'>
                                <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                                    <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                    <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                                </Button>
                                <div className='d-flex align-item-center justify-content-end'>
                                    <Link to='/admin/property'>
                                        <Button type='button' className='rz-button-outline-primary'>
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button
                                        className="rz-button-outline-primary mx-3"
                                        onClick={() => parentStepper.previous()}
                                    >
                                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                        <span className="align-middle d-sm-inline-block d-none">
                                            Back
                                        </span>
                                    </Button>
                                    <Button className='rz-button-primary' onClick={() => stepper.next()}>
                                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )
                }
            </Form>
        </Fragment>
    )
}

export default Location;