import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { configActions } from "redux/actions";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { propertyActions } from "redux/actions";
import { checkNotChannelPartner } from "utils";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from 'react-router-dom'
const Document = ({ parentStepper, stepper, type }) => {
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [currentState, setCurrentState] = useState()
  const [documentList, setDocumentList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const property = useSelector((state) => state.propertyReducer.property);
  const country = useSelector((state) => state.getCountryReducer.countryStates);

  useEffect(() => {
    if (currentState && property?.propertyDocument?.length > 0) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [currentState, property?.propertyDocument]);

  const { handleSubmit } = useForm();
  useEffect(() => {
    dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
  }, []);
  useEffect(() => {
    setIsModalOpen(false);
    if (country && property) {
      let state_name = "";
      let states = country?.states;
      for (let i = 0; i < states.length; i++) {
        if (states[i]?.id == (property?.propertyDocument?.length > 0 && property?.propertyDocument[0]?.documentList?.state_id)) {
          state_name = states[i]?.name
        }
      }
      setCurrentState({ value: property?.propertyDocument?.length > 0 && property?.propertyDocument[0]?.documentList?.state_id, label: state_name })
      onStateChange({ value: property?.propertyDocument?.length > 0 && property?.propertyDocument[0]?.documentList?.state_id, label: state_name })
    }
  }, [property]);
  useEffect(() => {
    if (country?.states) {
      const options = [];
      country?.states.map((item) => {
        options.push({ value: item.id, label: item.name });
      });
      setStates(options);
    }
  }, [country]);

  const onSubmit = (data) => {
    data.property_id = property.id;
    dispatch(propertyActions.storeMediaTwo(data, stepper, parentStepper));
  };

  const onStateChange = (item) => {
    var documents = country.states.find((o) => o.id == item.value);
    setDocumentList(documents?.documentList);
    setCurrentState(item)
  };

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const openFileModal = (e, item) => {
    setIsModalOpen(true);
    setSelectedDoc(item);
  };

  // to handle the user-selected file
  const handleChange = (e, item) => {
    const fileUploaded = e.target.files[0];
    const formData = new FormData();
    formData.append("property_id", property.id);
    formData.append("document_list_id", item.id);
    formData.append("document", fileUploaded);
    dispatch(propertyActions.storeMediaTwoDocs(formData));
  };
  // to handle the user-selected file
  const updateStatusofDocument = (item, doc, e) => {
    if (doc == undefined) {
      const formData = new FormData();
      formData.append("property_id", property.id);
      formData.append("document_list_id", item.id);
      formData.append("document", "");
      dispatch(propertyActions.storeMediaTwoDocs(formData));
    }
    else {
      let data = {
        doc_id: doc?.id,
        status: e.target.checked,
        property_id: property.id
      };
      dispatch(propertyActions.updateMediaTwoDocs(data));
    }
  };
  const renderPropertyDocs = (item) => {
    return checkIfAlreadyUploaded(item, (doc) => {
      return doc !== undefined ? (
        <span className="badge badge-success">Uploaded</span>
      ) : (
        <span>
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={(e) => openFileModal(e, item)}
          >
            <i className="fa fa-upload"></i>
          </button>
        </span>
      );
    });
  };
  const checkProtected = (item) => {
    return checkIfAlreadyUploaded(item, (doc) => {
      return doc !== undefined ? (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={doc?.protected}
            name={`property_document_protected_status`}
            onChange={(e) =>
              onDocumentStatusChange(doc?.id, e)
            }
            className="custom-control-input"
            id={`property_document_protected_status-${doc?.id}`}
          />
          <label
            className="custom-control-label"
            htmlFor={`property_document_protected_status-${doc?.id}`}
          >
            <Badge
              color=""
              className={`badge badge-pill badge-${doc?.protected == true
                ? "success"
                : "primary"
                } ml-0`}
            >
              {doc?.protected == true ? "Protected" : ""}
            </Badge>
          </label>
        </div>
      ) : (
        <></>
      );
    });
  };
  const checkStatus = (item) => {
    return checkIfAlreadyStatusUploaded(item, (doc) => {
      return (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={doc?.status}
            name={`property_document_status_update_doc_status-${item?.id}`}
            onChange={(e) => {
              updateStatusofDocument(item, doc, e)
            }
            }
            className="custom-control-input"
            id={`property_document_status_update_doc_status-${item?.id}`}
          />
          <label
            className="custom-control-label"
            htmlFor={`property_document_status_update_doc_status-${item?.id}`}
          >
            <Badge
              color=""
              className={`badge badge-pill badge-${doc?.status == true
                ? "success"
                : "primary"
                } ml-0`}
            >
              {doc?.status == true ? "" : ""}
            </Badge>
          </label>
        </div>)
    });
  };
  const renderPropertyDocsActions = (item) => {
    return checkIfAlreadyUploaded(item, (doc) => {
      return (
        doc !== undefined && (
          <span>
            <a
              href={doc.document_url}
              target="_blank"
              className="btn btn-link btn-sm"
            >
              <i className="fa fa-eye"></i>
            </a>
            <button
              type="button"
              className="btn btn-link btn-sm"
              onClick={() => onRemoveDocument(doc.id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </span>
        )
      );
    });
  };

  const onRemoveDocument = (id) => {
    dispatch(propertyActions.deleteMediaTwoDocs(id));
  };

  const checkIfAlreadyUploaded = (item, cb) => {
    if (property && property.propertyDocument) {
      let docs = property?.propertyDocument.find(
        (o) => (o.document_list_id == item.id && o.document_url != "")
      );
      return cb(docs);
    }
  };
  const checkIfAlreadyStatusUploaded = (item, cb) => {
    if (property && property.propertyDocument) {
      let docs = property?.propertyDocument.find(
        (o) => (o.document_list_id == item.id)
      );
      return cb(docs);
    }
  };
  const onDocumentStatusChange = async (doc_id, e) => {
    let data = {
      doc_id,
      protected: e.target.checked,
    };
    dispatch(propertyActions.updateMediaTwoDocs(data));
  };

  return (
    <Fragment>
      <div className="content-header">
        <h3 className="mb-0 all-page-heading rz-text-18">Documents</h3>
      </div>
      <div className="card">
        <div className="card-body">
          <h3 className="card-title all-page-heading rz-text-18">Asset documents</h3>
          <Row>
            <FormGroup tag={Col} md="6">
              <Label htmlFor="states">County</Label>
              <Select
                id="states"
                onChange={onStateChange}
                isClearable={false}
                className="basic-multi-select"
                options={states}
                classNamePrefix="select"
                value={currentState}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col}>
              <div className="table-responsive">
                <table className="table border">
                  <thead className="rz-thead-style">
                    <tr>
                      <th>DOCUMENT NAME</th>
                      <th>UPLOAD</th>
                      <th>DOCUMENT TYPE</th>
                      <th>ACTION</th>
                      {checkNotChannelPartner() &&
                        <>
                          <th>STATUS</th>
                          <th>PROTECTED</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {!documentList?.length && (
                      <tr className="text-center">
                        <td colSpan="4">No Document for Selected County.</td>
                      </tr>
                    )}
                    {documentList?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td scope="row">
                            {item.name}
                            <div>
                              <small className="text-muted">{item.type}</small>
                            </div>
                          </td>
                          <td>{renderPropertyDocs(item)}</td>
                          <td scope="row">
                            {item?.document_type}
                          </td>
                          <td>{renderPropertyDocsActions(item)}</td>
                          {checkNotChannelPartner() &&
                            <>
                              <td>{checkStatus(item)}</td>
                              <td>{checkProtected(item)}</td>
                            </>
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </FormGroup>
          </Row>
        </div>
      </div>

      {/* Upload Document File */}
      {selectedDoc && (
        <Modal isOpen={isModalOpen} centered={true}>
          <ModalHeader
            close={<i className="fa fa-times"></i>}
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Upload Document
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={(e) => handleChange(e, selectedDoc)}
                  accept={selectedDoc?.type}
                />
                <label className="custom-file-label" for="customFile">
                  Choose file
                </label>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between mt-5">
          {property?.propertyDocument?.length > 0 &&
            <Button type='button' color='success' onClick={() => parentStepper.next()} className='btn-next' >
              <span className='align-middle d-sm-inline-block d-none'>Skip</span>
              <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
            </Button>
          }
          <div className="d-flex ">
            <Link to='/admin/property'>
              <Button type='button' className='rz-button-outline-primary'>
                Cancel
              </Button>
            </Link>
            <Button
              className="rz-button-outline-primary mx-3"
              onClick={() => stepper.previous()}
            >
              <BsArrowLeftShort style={{ fontSize: "22px" }} />
              <span className="align-middle d-sm-inline-block d-none">
                Back
              </span>
            </Button>

            <Button type="submit" className="rz-button-primary" disabled={!isSaveEnabled}>
              <BsArrowRightShort style={{ fontSize: "22px" }} />
              <span className="align-middle d-sm-inline-block d-none">
                Next
              </span>

            </Button>
          </div>
        </div>
      </Form>
    </Fragment>
  );
};

export default Document;
