import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import { theme } from "utils/theme";

const loginSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    phone: yup.string().length(9, 'Phone number must be 9 digit'),
});

const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema) });
    const dispatch = useDispatch();
    const loggingIn = useSelector(state => state.loginReducer.loggingIn);
    const history = useHistory();

    function onLoginSubmit(data) {
        data.country_code = '254'
        data.role = 4
        dispatch(userActions.register(data, history));
    }

    return (
        // <Card>
        <div className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
                <img
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        objectFit: "contain"
                    }}
                    alt="..."
                    src={require("./../../assets/img/brand/playstore-icon.png").default}
                />
                <h3 className="mt-2">Welcome to Ownmali! 👋</h3>
                <small>Please sign-in to your account and start the adventure</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onLoginSubmit)}>
                <FormGroup>
                    <Label for="phone" className=" text-muted"> Full name </Label>
                    <input
                        placeholder="Enter Full name"
                        {...register('name')}
                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" />
                    <FormFeedback> {errors.name?.message} </FormFeedback>
                    <Label for="phone" className="mt-2 text-muted"> Mobile Number </Label>
                    <input
                        placeholder="Enter Phone mobile"
                        {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" />
                    <FormFeedback> {errors.phone?.message} </FormFeedback>
                </FormGroup>
                <div className="text-center">
                    <Button type="submit"
                        className="my-1 w-100"
                        color="primary"
                        disabled={loggingIn}>
                        {loggingIn ? 'Please wait...' : 'Sign Up'}
                    </Button>
                </div>
                <div className="text-center text-muted mb-4">
                    <p className="mt-2 text-muted"
                    >
                        Already a member?
                        <a
                            href="/auth/login"
                            style={{
                                cursor: "pointer",
                                marginLeft: 4,
                                color: theme?.colors?.primary[500]
                            }}>
                            Login here
                        </a>
                    </p>
                </div>
            </Form>
        </div>
        // </Card>
    );
};

export default Register;
