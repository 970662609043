import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Badge, Table, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconEyeImage from "./../../../assets/img/Icon-eye.png"
import { TbExternalLink } from "react-icons/tb";
import { FiChevronDown } from "react-icons/fi";
import { moneyFormat } from "utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { useState } from "react";
import { useEffect } from "react";
import { useUpdatePayout } from "hooks/useRentalPayout";
import { toast } from "react-toastify";
const styles = {
    checkbox: {
        width: "18px",
        height: "18px",
        cursor: "pointer"
    }
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}
const textStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 500,
}
const spanStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontWeight: 400,
}
const ShareHolderList = ({ data = [], refetch }) => {
    const { mutate: updatePayout } = useUpdatePayout()
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [admin_id, setAdminId] = useState(null);
    const [selectedAdmin, setSelectedAdmin] = useState(null)
    const [payout_id, setPayoutId] = useState(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    useEffect(() => {
        if (selectedItems.length === data.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedItems, data]);
    useEffect(() => {
        if (data.length != 0) {
            setSelectedAdmin(data[0].admin_user_id);
        }
    }, [data])
    const handleCheckboxChange = (itemId) => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
            setSelectAll(false);
        } else {
            const allItemIds = data.map((item) => item.id);
            setSelectedItems(allItemIds);
            setSelectAll(true);
        }
    };

    const performActions = async () => {
        await updatePayout({

        }, {
            onSuccess: (res) => {

                //refetch()
            }
        })
    };
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    const singleSkip = async (item) => {
        await updatePayout({
            admin_user_id: item.admin_user_id,
            payout_ids: [item.id],
            status: "Hold"
        }, {
            onSuccess: (res) => {
                refetch();
                toast.success('Status Update successfully')
            },
            onError: (err) => {
                toast.error('Something went wrong')
            }
        })
    }
    const selectedSkip = async () => {
        await updatePayout({
            admin_user_id: selectedAdmin,
            payout_ids: selectedItems,
            status: "Hold"
        }, {
            onSuccess: (res) => {
                refetch();
                toast.success('Status Update successfully')
            },
            onError: (err) => {
                toast.error('Something went wrong')
            }
        })
    }
    const confirmPayment = async () => {
        await updatePayout({
            admin_user_id: admin_id,
            payout_ids: [payout_id],
            status: "Paid"
        }, {
            onSuccess: (res) => {
                refetch();
                toast.success('Status Update successfully')
                toggle();

            },
            onError: (err) => {
                toggle();
                toast.error('Something went wrong')
            }
        })
    }
    return (
        <>
            <Card className="border-none">
                <Table responsive className="custom-table-div">

                    <thead className="page-thead-style">
                        <tr>
                            <th>
                                <div className="d-flex align-items-center">
                                    <input
                                        style={styles.checkbox}
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                    {
                                        selectedItems.length != 0 &&
                                        <UncontrolledDropdown nav>
                                            <DropdownToggle nav>
                                                <FiChevronDown style={{ color: "#6E6B7B", fontSize: "20px" }} />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem onClick={selectedSkip}>
                                                    Skip Payment
                                                </DropdownItem>
                                                <DropdownItem >
                                                    Confirm Payment
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    }
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    Shareholder name
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    payment type
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    approved by
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>


                            <th>
                                <div className="d-flex align-items-center">
                                    AMOUNT(Gross)
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    AMOUNT(Net)
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    payment status
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>

                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="page-tbody-cell">
                        {(
                            data?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <input
                                                style={styles.checkbox}
                                                type="checkbox"
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => handleCheckboxChange(item.id)} />
                                        </td>
                                        <td
                                            style={{ cursor: 'pointer' }}
                                            className="text-primary">
                                            <NavLink
                                                to={{
                                                    pathname: `/admin/`,

                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div>{item?.user?.name} </div> <TbExternalLink style={{ marginLeft: "5px", fontSize: "20px" }} />
                                                </div>
                                            </NavLink>
                                        </td>
                                        <td> {item?.payment_type} </td>
                                        <td> {item?.admin?.name} </td>
                                        <td> {moneyFormat(item?.amount)} </td>
                                        <td> {moneyFormat(item.amount || 0 - item.tax || 0)} </td>
                                        <td>
                                            {
                                                item?.status == 'Pending' ?
                                                    <Badge className="rental-payout-pending">
                                                        Yet to pay
                                                    </Badge> : item?.status == 'Hold' ?
                                                        <Badge className="rental-payout-hold">
                                                            On Hold
                                                        </Badge>

                                                        : <Badge className="rental-payout-paid">
                                                            Payment done
                                                        </Badge>
                                            }
                                        </td>
                                        <td className="text-left">
                                            <UncontrolledDropdown nav>
                                                <DropdownToggle nav>
                                                    <BsThreeDotsVertical style={{ color: "#7367F0", fontSize: "20px" }} />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem onClick={() => singleSkip(item)}>
                                                        Skip Payment
                                                    </DropdownItem>
                                                    <DropdownItem onClick={
                                                        async () => {
                                                            setPayoutId([item?.id])
                                                            setAdminId(item?.admin_user_id);
                                                            toggle()
                                                        }}>
                                                        Confirm Payment
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Card>
            <Modal centered={true} isOpen={modal} toggle={toggle} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Confirm payout request</h1>
                </ModalHeader>
                <ModalBody>
                    <p style={textStyle}>Please confirm the details and submit for payout

                    </p>
                    <div ><span style={spanStyle}>Amount :</span>  KES 14,09,000</div>
                    <div> <span style={spanStyle}>Company : </span>Jungle Bells</div>
                    <div> <span style={spanStyle}> LLP Bank Account : </span>ICICI Bank [*******1345]</div>


                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary" outline onClick={toggle}>
                        Go Back
                    </Button>
                    <Button onClick={confirmPayment} color="primary">
                        Confirm
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    );
};

export default ShareHolderList;
