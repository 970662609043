import React from 'react'
import { propertyActions } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import ABI from './../../../contract/abi/RyzerCoin.json';
import bytecode from './../../../contract/bytecode/RyzerCoin.json';
import { ethers } from 'ethers';
import { getExplorer } from '../../../helpers/networks';
import { useUpdatePropertySto } from '../../../hooks/useProperty';
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { addTokenToMetamask } from 'utils';
import { useWeb3ModalNetwork } from '@web3modal/react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const stoSchema = yup.object().shape({
  name: yup.string("name is string.").required("name is Required."),
  symbol: yup.string("symbol is string.").required("symbol is Required."),
});
export default function CreateERC20({ stepper, type }) {
  const dispatch = useDispatch();
  const property = useSelector(state => state.propertyReducer.property);

  const { register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors } } = useForm({
      resolver: yupResolver(stoSchema),
    });

  const [name, setName] = React.useState("")
  const [symbol, setSymbol] = React.useState("")

  const { mutate } = useUpdatePropertySto()
  const { isConnected, address } = useAccount()
  const { selectedChain } = useWeb3ModalNetwork()

  const handleSTO = async () => {
    if (!isConnected) {
      toast.error("Please connet your wallet first.")
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const factory = new ethers.ContractFactory(ABI, bytecode, signer)
    var contract;
    try {
      contract = await factory.deploy(name, symbol);
    }
    catch (err) {
      console.log(err);
      return;
    }
    reset();
    var data = {}
    data.property_id = property?.id
    data.sto_erc20_address = contract?.address;
    data.is_sto_created = true;
    data.sto_symbol = symbol;
    data.sto_name = name;
    data.sto_owner_address = address
    data.blockchain_metadata = selectedChain;
    await mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        stepper.next();
      }
    })
    dispatch(propertyActions.getProperty(property?.id))
  }
  return (
    <>
      <div className='content-header'>
        <h3 className='mb-0 rz-heading rz-text-18'>Create Security Token</h3>
      </div>
      {!property?.is_sto_created ? (
        <Card className="p-4">
          <Row className="justify-content-between">
            <Col>
              <Form >
                <Row>
                  <Col className="my-2" md="12" xs="12">
                    <Label> Name of Security Token (Without Space)</Label>
                    <input
                      className="form-control"
                      type="text"
                      value={name} onChange={e => setName(e.target.value)}
                    />
                  </Col>
                  <Col className="my-2" md="12" xs="12">
                    <Label> Symbol of Security Token (All Capital)</Label>
                    <input
                      className="form-control"
                      type="text"
                      value={symbol} onChange={e => setSymbol(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          <a
            className="btn btn-primary mt-1"
            href={`${property?.blockchain_metadata?.blockExplorers?.default?.url}/token/${property?.sto_erc20_address}`}
            target="_blank"
            rel="noreferrer"
          >
            View Security token on Explorer
          </a>
          <Button
            color="primary"
            className='mt-1'
            onClick={() => addTokenToMetamask(
              property?.sto_erc20_address,
              property?.sto_symbol,
              18,
            )}
          >
            Add Token Into Metamask
          </Button>
          <Col className="my-4" md="6" xs="6">
            <Label> Your Security token Symbol</Label>
            <h1>{property?.sto_symbol}</h1>
          </Col>
          <Col className="my-4" md="6" xs="6">
            <Label> Your Security token Name</Label>
            <h1>{property?.sto_name}</h1>
          </Col>
          <Col className="my-4" md="12" xs="12">
            <Label> Your Security token Address</Label>
            <h1>{property?.sto_erc20_address}</h1>
          </Col>
          <Col className="my-4" md="12" xs="12">
            <Label> Owner Address</Label>
            <h1>{property?.sto_owner_address}</h1>
          </Col>
          <Col className="my-4" md="12" xs="12">
            <Label>Blockchain Network</Label>
            <h1>{property?.blockchain_metadata?.name}</h1>
          </Col>
        </>

      )}
      <div className='mt-5 d-flex align-items-center justify-content-between'>
        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
          <span className='align-middle d-sm-inline-block d-none'>Skip</span>
          <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
        </Button>
        <div className='d-flex align-items-center justify-content-end'>
          <Link to='/admin/property'>
            <Button type='button' className='rz-button-outline-primary'>
              Cancel
            </Button>
          </Link>
          <Button className='rz-button-outline-primary ml-3 mr-2' onClick={() => stepper.previous()}>
            <BsArrowLeftShort style={{ fontSize: "22px" }} />
            <span className='align-middle d-sm-inline-block d-none'>Back</span>
          </Button>

          <Button type='button'
            className='rz-button-primary'
            disabled={property?.is_sto_created ? true : false}
            onClick={() => handleSTO()}  >
            <BsArrowRightShort style={{ fontSize: "22px" }} />
            Next
          </Button>
        </div>
      </div>
    </>
  )
}

