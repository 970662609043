import { Fragment, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { produce } from "immer";
import { FaRegTrashAlt } from "react-icons/fa";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import { useParams } from "react-router-dom";
import { truncate, moneyFormat, getPropertyValue } from "utils";
import { AiOutlinePercentage } from "react-icons/ai";
import moment from "moment";
import { toast } from "react-toastify";
import { useClearProperty } from "hooks/useProperty";
import { getInterestPercentageSimpleIntrest } from "helpers/functions";
import { sortByMonth } from "helpers/functions";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { HiOutlineDocumentText, HiOutlinePlusCircle } from "react-icons/hi";
import { BiDollar, BiUser } from "react-icons/bi";
import classNames from "classnames";
const plotSchema = yup.object().shape({
  status: yup.boolean(),
  investment_type: yup.boolean(),
  // no_sqyds: yup.number().when('status', (status, schema) => {
  // return status ? schema.required() : schema.nullable();
  //}),
  // min_sqyds_investment: yup
  //   .number()
  //   .when(["status", "investment_type"], (status, investment_type, schema) => {
  //     return status && investment_type ? schema.required() : schema.nullable();
  //   }),
  // no_units: yup
  // .number()
  // .when(["status", "investment_type"], (status, investment_type, schema) => {
  //   return status && !investment_type ? schema.required() : schema.nullable();
  // }),
});

const PlotDetails = ({ stepper, type, parentStepper }) => {
  const dispatch = useDispatch();
  var { property_id } = useParams();
  var months = moment.monthsShort();
  const [isModalOpen, setIsModalOpen] = useState(false);
  months = months?.map((m) => {
    return {
      month: m,
      price: 0,
    };
  });
  const currentYear = new Date().getFullYear();
  const plotSvg = useRef();
  const plotFill = {
    investment: "#FF9F43",
    selected: "#725AEC",
    disabled: "#E8E8E8",
    available: "#FFF",
    coowner: "#28C76F",
  };
  var plotList = [];

  const { property } = useSelector((state) => state.propertyReducer);
  const disable = (property?.sto_sale_address != "")

  const [plotStatus, setPlotStatus] = useState(false);
  const [isFractional, setIsFractional] = useState(false);
  const [prevSelectedPlotIndex, setPrevSelectedPlotIndex] = useState(null);
  const [prevSelectedPlotFill, setPrevSelectedPlotFill] = useState(null);
  const [selectedBlockId, setSelectBlockId] = useState(null);
  const [plotId, setPlotId] = useState(null);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [plotsResi, setplotsResi] = useState([]);
  const [plotLeft, setPlotLeft] = useState(0);
  const [propertyPlot, setPropertyPlot] = useState(false);
  const [plotFile, setPlotFile] = useState(
    require("./../../../assets/img/empty-box.png").default
  );
  const { mutate: clearPropertyAllData, isLoading: loading } =
    useClearProperty();
  const clearProperty = async () => {
    const confirmed = window.confirm(
      `Are you sure? you want to clear all property data.this action is not revatable.this will erase all property plot and other infomation related to property.?`
    );
    if (confirmed) {
      await clearPropertyAllData(
        {
          property_id: property_id,
        },
        {
          onSuccess: (res) => {
            toast.success("Property Data Clear Successfully");
            return;
          },
        }
      );
    }
  };
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(plotSchema),
    defaultValues: {
      year: currentYear,
      years: [],
    },
  });
  const {
    fields: yearsFields,
    append: appendYear,
    remove: removeYear,
  } = useFieldArray({
    control,
    name: "years",
  });
  useEffect(() => {
    if (property) {
      setValue("start_with", property?.start_with);
      if (property?.propertyPlot?.length == 1) {
        setValue("base_price", propertyPlot?.base_price);
        setValue("current_year_growth_percent", property?.current_year_growth_percent);
      }
      if (
        property?.share_type == "NCD" &&
        property?.propertyPlot?.length > 0
      ) {
        const propertyPlot = property.propertyPlot[0];
        setValue("fixed_return", property?.ncd_metadata?.fixed_return);
        setValue("security_x_cover", property?.ncd_metadata?.security_x_cover);
        setValue("no_sqyds", propertyPlot?.no_sqyds);
      }
      if (
        property?.stage == "Under Construction" &&
        property?.propertyPlot?.length > 0
      ) {
        const propertyPlot = property.propertyPlot[0];
        setValue("max_sqyds_investment", propertyPlot?.max_sqyds_investment);
        setValue("min_maturity", property?.min_maturity);
        setValue("risk_analysis", property?.risk_analysis);
        setValue(
          "investment_performance_description",
          property?.investment_performance_description
        );
        if (property?.propertyPerformance?.length > 0) {
          setValue("years", []);
          for (let i in property.propertyPerformance) {
            appendYear({
              _id: property.propertyPerformance[i]?.id,
              year: property.propertyPerformance[i]?.year,
              month: property.propertyPerformance[i]?.month,
              irr: property.propertyPerformance[i]?.target_irr,
              share_market_percent:
                property?.propertyPerformance[i]?.share_market_percent,
              mutual_fund_percent:
                property?.propertyPerformance[i]?.mutual_fund_percent,
              fixed_deposit_percent:
                property?.propertyPerformance[i]?.fixed_deposit_percent,
              metadata:
                property?.propertyPerformance[i]?.metadata,
            });
          }
        } else {
          appendYear({});
        }
      }
    }
  }, [property]);
  const {
    register: register2,
    setValue: setValue2,
    getValues: getValues2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({});

  const year = watch("year");
  const [monthData, setMonthData] = useState(months);
  const [annual_growth_percent, setannual_growth_percent] = useState(0);
  const [disbale_month, setdisbale_month] = useState(false);
  const [current_month_price, setcurrent_month_price] = useState(0);
  const [growth_percent_rate, setgrowth_percent_rate] = useState(undefined);
  const [residentPlotType, setResidentPlotType] = useState("dynemic");
  const checkProperty = getPropertyValue(property);
  const asset_type = property?.property_type_id;
  useEffect(async () => {
    if (property) {
      if (checkProperty != 3) {
        var plot = await property?.propertyPlot?.find(
          (o) => o?.id === parseInt(plotId)
        );
      } else {
        var plot =
          (await property?.propertyPlot?.length) > 0
            ? property?.propertyPlot?.[0]
            : {};
      }
      setSelectedPlot(plot);
      var plotpricedata = plot?.propertyPlotPrice?.filter(
        (p) => p.year == (year || currentYear)
      );
      if (Object.keys(plot || {})?.length == 0) {
        setMonthData(months);
        return;
      }
      var monthsd = plotpricedata?.map((p) => {
        return {
          month: p.month,
          price: p.price,
        };
      });
      if (monthsd?.length <= 0) setMonthData(months);
      else {
        monthsd = sortByMonth(monthsd);
        setMonthData(monthsd);
      }

      // let tempmonthData = monthsd?.filter((month) => month?.month == "Jan");
      // setcurrent_month_price(tempmonthData?.length > 0 ? tempmonthData[0]?.price : 0)
      setcurrent_month_price(plot?.current_month_price || 0);
      if (year > currentYear) {
        let previousYearpercentage, currentYearPercenatge;
        let jan_price = plot?.propertyPlotPrice
          ?.filter((p) => p.year == year - 1)
          ?.filter((month) => month?.month == "Jan")?.[0]?.price;
        let dec_price = plot?.propertyPlotPrice
          ?.filter((p) => p.year == year - 1)
          ?.filter((month) => month?.month == "Dec")?.[0]?.price;
        if (dec_price && dec_price != 0 && jan_price && jan_price != 0) {
          previousYearpercentage = getInterestPercentageSimpleIntrest(
            parseFloat(dec_price),
            parseFloat(jan_price)
          );
        }
        jan_price = plotpricedata?.filter((month) => month?.month == "Jan")?.[0]
          ?.price;
        dec_price = plotpricedata?.filter((month) => month?.month == "Dec")?.[0]
          ?.price;
        if (dec_price && dec_price != 0 && jan_price && jan_price != 0) {
          currentYearPercenatge = getInterestPercentageSimpleIntrest(
            parseFloat(dec_price),
            parseFloat(jan_price)
          );
        }
        let grouthTemp =
          Math.round(
            (parseFloat(currentYearPercenatge) -
              parseFloat(previousYearpercentage) +
              Number.EPSILON) *
            100
          ) / 100;
        setgrowth_percent_rate(grouthTemp || 0);
      }
      if (growth_percent_rate && year != currentYear) {
        setdisbale_month(true);
      } else {
        setdisbale_month(false);
      }
    }
  }, [year, property]);

  useEffect(async () => {
    if (growth_percent_rate && year != currentYear) {
      setdisbale_month(true);
    } else {
      setdisbale_month(false);
    }
  }, [growth_percent_rate]);

  useEffect(() => {
    // check if property is set
    if (property && checkProperty == 1) {
      if (property_id == null) property_id = property.id;

      if (property.svg_elements) {
        svgPlotClickListner();
      }
      // count property plots
      const { svg_elements_count, propertyPlot } = property;
      if (propertyPlot) {
        let left =
          propertyPlot?.length >= svg_elements_count
            ? 0
            : svg_elements_count - propertyPlot.length;
        setPlotLeft(left);
      }
    } else if (property && checkProperty == 3) {
      if (property?.propertyPlot?.length > 0)
        setPlotId(property?.propertyPlot[0]?.id);
      else setPlotId(null);
    } else if (property && checkProperty == 2) {
      const output = [];
      if (property?.propertyPlot != undefined) {
        const groups = property?.propertyPlot?.reduce((groups, item) => {
          const group = groups.find((group) => group[0].uuid === item.uuid);
          if (group) {
            group.push(item);
          } else {
            groups.push([item]);
          }
          return groups;
        }, []);

        groups?.forEach((group) => {
          const sortedGroup = group.sort((a, b) =>
            parseFloat(a.block_id) > parseFloat(b.block_id) ? 1 : -1
          );
          output.push(sortedGroup);
        });

        output.sort((a, b) =>
          parseFloat(a[0].uuid) < parseFloat(b[0].uuid) ? 1 : -1
        );
        setplotsResi(output);
      }
    }
  }, [property, selectedBlockId, plotStatus]);

  useEffect(() => {
    if (plotId) getSelectedPlot(plotId);
  }, [plotId]);

  const onBlockSubmit = (data) => {
    trigger();
    if (plotStatus) {
      if (
        property?.share_type != "NCD" &&
        (data?.year == "" || data?.year == "Select Year")
      ) {
        toast.error("Please Select Year");
        return;
      }
      if (!data?.no_sqyds) {
        toast.error("Total No. Of Tokens is required.");
        return;
      }
      if (!data?.min_sqyds_investment) {
        toast.error("Minimum No. of Tokens is required.");
        return;
      }
    }
    if (property?.share_type == "NCD") {
      data.months = [Number(data.base_price)];
    } else {
      data.months = monthData?.map((m) => m.price);
      var checkMonth = 0;
      for (let i in data?.months) {
        if (data?.months[i] != "" && parseFloat(data?.months[i] || 0) != 0)
          checkMonth++;
      }
    }

    if (
      property?.share_type != "NCD" &&
      property.stage != "Under Construction" &&
      (growth_percent_rate == undefined || growth_percent_rate == "")
    ) {
      if (checkMonth == 2) {
        const newArray = [data?.months?.[0], data?.months?.[11]];
        if (parseFloat(newArray?.[0] || 0) <= 0) {
          toast.error("Please Enter Jan Month Value or All Value");
          return;
        }
        if (parseFloat(newArray?.[1] || 0) <= 0) {
          toast.error("Please Dec Month Value or All Value");
          return;
        }
        data.months = newArray;
      } else {
        for (let i in data?.months) {
          if (parseFloat(data?.months[i]) == 0) {
            toast.error("Zero Value is Not Allowed");
            return;
          }
        }
      }
    }
    data.investment_type = "Fractional";
    if (checkProperty != 3) {
      if (data.status) {
        data.investment_type = data.investment_type
          ? "Fractional"
          : "Co-ownership";
      }
      data.status = data.status ? "Active" : "Inactive";
      data.block_id = selectedBlockId;
    } else {
      data.investment_type = "Fractional";
      data.block_id = 1;
      data.status = "Active";
    }
    if (plotId) {
      data.plot_id = plotId;
    }
    data.property_id = property.id;

    if (property?.share_type == "NCD") {
      data.year = currentYear;
    } else {
      data.growth_percent_rate = growth_percent_rate;
    }
    if (property?.share_type == "NCD") {
      data.no_sqyds = data.no_sqyds;
    }
    data.stage = property?.stage;
    data.share_type = property?.share_type;
    dispatch(propertyActions.saveAboutTwoBlock(data, stepper));
    setPrevSelectedPlotIndex(null);
  };
  const onFileChange = (e) => {
    const confirmed = window.confirm(
      `Are you sure? Please do note that uploading new SVG file will delete all previous plot records for this property if added.`
    );
    if (confirmed) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("property_id", property.id);
      formData.append("svg", file);
      dispatch(propertyActions.savePropertySvg(formData));
    }
  };

  function onPlotClick(list, i) {
    // if clicks on same plot twice do nothing
    if (prevSelectedPlotIndex == i) return false;

    // reset when plot changed
    reset();
    setValue("status", false);
    setValue("investment_type", false);
    setPlotStatus(false);
    setIsFractional(false);
    setPropertyPlot(false);

    // reset last selected plot
    if (prevSelectedPlotIndex !== null) {
      plotList[prevSelectedPlotIndex].setAttribute(
        "fill",
        prevSelectedPlotFill
      );
    }
    setPropertyPlot({});
    setPrevSelectedPlotIndex(i);
    setPrevSelectedPlotFill(list.getAttribute("fill"));
    setSelectBlockId(list.getAttribute("block-id")); // plot primary id
    setPlotId(list.getAttribute("property-plot-id")); // plot id
    list.setAttribute("fill", plotFill.selected);
  }

  useEffect(async () => {
    let tempmonthDataJan = monthData?.filter((month) => month?.month == "Jan");
    let tempmonthDataDec = monthData?.filter((month) => month?.month == "Dec");
    if (tempmonthDataJan?.length == 1 && tempmonthDataDec?.length == 1) {
      const jan_price = tempmonthDataJan?.[0]?.price;
      const dec_price = tempmonthDataDec?.[0]?.price;
      if (dec_price != 0 && jan_price != 0) {
        let percentage = getInterestPercentageSimpleIntrest(
          dec_price,
          jan_price
        );
        setannual_growth_percent(percentage || 0);
      } else {
        setannual_growth_percent(0);
      }
    }
  }, [monthData]);

  const getSelectedPlot = async () => {
    if (
      plotId === "undefined" ||
      plotId === null ||
      property?.propertyPlot === undefined
    )
      return;
    if (checkProperty != 3) {
      var plot = await property?.propertyPlot.find(
        (o) => o.id === parseInt(plotId)
      );
    } else {
      var plot =
        (await property?.propertyPlot?.length) > 0
          ? property?.propertyPlot[0]
          : {};
    }
    setSelectedPlot(plot);
    var plotpricedata = plot?.propertyPlotPrice?.filter((p) => p.year == year);
    var monthsd = plotpricedata?.map((p) => {
      return {
        month: p.month,
        price: p.price,
      };
    });
    if (monthsd?.length <= 0) setMonthData(months);
    else {
      monthsd = sortByMonth(monthsd);
      setMonthData(monthsd);
    }
    // let tempmonthData = monthsd?.filter((month) => month?.month == "Jan");
    // setcurrent_month_price(tempmonthData?.length > 0 ? tempmonthData[0]?.price : 0)

    if (plot !== undefined) {
      var status = plot.status == "Active" || plot.status == "Fully Invested";
      var investment_type = plot.investment_type == "Fractional";
      setValue("status", status);
      setValue("investment_type", investment_type);
      setValue("no_sqyds", plot.no_sqyds);
      setValue("no_units", plot.no_units);
      setValue("min_sqyds_investment", plot.min_sqyds_investment);
      setValue("sqyds_start_price", plot.sqyds_start_price);
      setValue("sqyds_end_price", plot.sqyds_end_price);
      setPlotStatus(status);
      setIsFractional(investment_type);
      setPropertyPlot(plot);
      setcurrent_month_price(plot?.current_month_price || 0);
      setValue("plot_dimension", plot.plot_dimension);
      setValue("house_type", plot.house_type);
      // setannual_growth_percent(plot.annual_growth_percent)
      setPlotId(plotId);
    }
  };

  function svgPlotClickListner() {
    if (
      property?.property_type_id == 1 &&
      plotSvg.current.firstElementChild !== null
    ) {
      plotList = [...plotSvg.current.firstElementChild.children];
      plotList.shift();
      plotList.map((list, index) => {
        list.classList.add("plot-svg");
        list.onclick = () => onPlotClick(list, index);
      });
    }
  }

  function onPlotStatusChange(e) {
    setPlotStatus(e.target.checked);
    reset();
    setValue("status", e.target.checked);
    setValue("investment_type", true);
    setIsFractional(true);
  }

  function onPlotInvestmentTypeChange(e) {
    setIsFractional(true);
    reset();
    setValue("investment_type", true);
    setValue("status", plotStatus);
    setPropertyPlot(true);
  }

  const AddAllPlot = async () => {
    var data = [];
    plotList = [...plotSvg.current.firstElementChild.children];
    plotList.shift();
    plotList.map((list, index) => {
      var newPlot = {
        status: "Inactive",
        investment: false,
      };
      newPlot.block_id = list.getAttribute("block-id");
      newPlot.property_id = property.id;
      data.push(newPlot);
    });
    dispatch(propertyActions.saveAboutBlukAddBlock({ plots: data }));
  };
  function onSaveNextClick() {
    if (property?.stage == "Under Construction") {
      parentStepper?.next();
      return;
    }
    else {
      stepper?.next();
      return;
    }
    const confirmed = window.confirm(
      `Are you sure? Please do note that property performance and growth will be recalculated in the next tab.If you have not change in the plot you can use Skip button to proceed further.`
    );
    if (confirmed && plotLeft == 0) {
      dispatch(
        propertyActions.saveAboutTwo({ property_id: property.id }, stepper)
      );
    }
  }
  const calculateWidth = (subArrayLength) => {
    subArrayLength = subArrayLength == 0 ? 1 : subArrayLength;
    return 80 / subArrayLength;
  };
  const calculateMargin = (subArrayLength) => {
    subArrayLength = subArrayLength == 0 ? 1 : subArrayLength;
    return 3 / subArrayLength;
  };
  const onFloorSubmit = (data) => {
    if (!data && residentPlotType == "dynemic") {
      if (!getValues2("number_of_assert")) {
        toast.error("Please Define Number of Asset");
        return;
      }
    }
    let formData;
    if (data) {
      formData = {
        uuid: data.uuid, //floor no
      };
    } else {
      formData = {
        uuid: getValues2("uuid"), //floor no
        plot_name: getValues2("plot_name"), //floor name
        number_of_assert: getValues2("number_of_assert"), //number of plot need to create
      };
    }
    reset2();
    setIsModalOpen(false);
    dispatch(
      propertyActions.saveAboutBlukAddBlock({
        floor: formData,
        is_delete: data ? true : false,
        property_id: property.id,
      })
    );
  };
  const onClickResidensialPlot = (data) => {
    setPlotStatus(false);
    setSelectBlockId(data?.block_id); // plot primary id
    setPlotId(data?.id); // plot id
  };
  const BlankView = () => {
    return (
      <Row className="p-5">
        <Col className="d-flex align-items-center justify-content-center">
          <div className="text-center">
            <img src={require("../../../assets/img/file-drawer.png").default} />
            <p>Select a plot to view details</p>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <Fragment>
      <div className="content-header">
        <h3 className="mb-0 text-muted">Price details</h3>
      </div>
      {/*
            Asset Categoty-: Developed Land
            1. Land Parcel (Developed Land)
             */}
      {checkProperty == 1 && (
        <>
          <Row>
            <Col>
              <FormGroup className="d-flex justify-content-between">
                <div>
                  <h5 className="mb-0 text-muted">
                    Upload property’s SVG image
                  </h5>
                  <small className="text-muted">
                    Upload only svg file format
                  </small>
                </div>
                <div className="custom-file w-75">
                  <input
                    type="file"
                    name="plot_file"
                    onChange={onFileChange}
                    className="custom-file-input"
                    id="customFile"
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose file
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onBlockSubmit)}>
            <Row>
              <Col className="">
                <div
                  className="card rz-card-border  p-3"
                  style={{ borderRadius: "6px" }}
                >
                  <Row>
                    <Col lg="2">
                      {selectedBlockId && (
                        <Row>
                          <Col lg="12">
                            <small>Asset Status</small>
                            <div className="custom-control custom-switch">
                              {/* check if toggle perform on selected plot/block */}
                              <input
                                type="checkbox"
                                name="plotStatus"
                                {...register(`status`, {
                                  onChange: (e) => onPlotStatusChange(e),
                                })}
                                className="custom-control-input"
                                id="plot-status"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="plot-status"
                              ></label>
                            </div>
                          </Col>
                          {plotId ? (
                            <Col lg="12" className="my-3">
                              {property?.property_type_id == 1 && (
                                <small className="text-sm">Plot ID</small>
                              )}
                              {property?.property_type_id == 2 && (
                                <small className="text-sm">Flat ID</small>
                              )}
                              {property?.property_type_id == 4 && (
                                <small className="text-sm">Flat ID</small>
                              )}
                              <div className="text-sm font-weight-bold">
                                {plotId}
                              </div>
                            </Col>
                          ) : null}
                          {/* {plotStatus && (
                                                        <Col lg='12'>
                                                            <small>Fractional ?</small>
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox"
                                                                    disabled={true}
                                                                    {...register(`investment_type`, { onChange: (e) => onPlotInvestmentTypeChange(e) })}
                                                                    className="custom-control-input" id="plot-investment" />
                                                                <label className="custom-control-label" htmlFor="plot-investment"></label>
                                                            </div>
                                                        </Col>
                                                    )} */}
                        </Row>
                      )}
                      {plotLeft != 0 ? (
                        <Button color="primary" onClick={() => AddAllPlot()}>
                          Add All Asset
                        </Button>
                      ) : null}
                    </Col>
                    <Col lg="10">
                      <div className="card border-secondary">
                        <div className="card-body d-flex align-items-center justify-content-center">
                          {property?.svg_elements ? (
                            <div
                              ref={plotSvg}
                              id="plot-svg"
                              dangerouslySetInnerHTML={{
                                __html: property?.svg_elements,
                              }}
                            />
                          ) : (
                            <img
                              className="card-img-top img-fluid"
                              src={plotFile}
                              alt="Image Preview"
                              style={{ width: "250px" }}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-3 border-bottom">
                    <Col className="d-flex align-items-center">
                      <div className="mr-3 text-sm">
                        <span className="dot text-secondary">.</span> Available
                      </div>
                      <div className="mr-3 text-sm">
                        <span className="dot text-gray">.</span> Inactive
                      </div>
                      {/* <div className='mr-3 text-sm'><span className="dot text-success">.</span> Co-Ownership</div> */}
                      {/* <div className='mr-3 text-sm'><span className="dot" style={{ color: '#FF9F43' }}>.</span>Fractional</div> */}
                      <div className="mr-3 text-sm">
                        <span className="dot text-primary">.</span> Selected
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {
                      // if selected plot status is active
                      plotStatus && selectedBlockId != null ? (
                        <>
                          <Row className="my-3">
                            <FormGroup tag={Col} md="3">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`no_sqyds`}
                              >
                                Total number of sqyds{" "}
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`no_sqyds`}
                                {...register(`no_sqyds`)}
                                className={classnames({
                                  "is-invalid": errors.no_sqyds,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            {isFractional ? (
                              <FormGroup tag={Col} md="4">
                                <Label
                                  className="form-label"
                                  style={{ fontSize: 14 }}
                                  htmlFor={`min_sqyds_investment`}
                                >
                                  Minimum sqyds to invest{" "}
                                  <span className="required-star">*</span>
                                </Label>
                                <input
                                  id={`min_sqyds_investment`}
                                  {...register(`min_sqyds_investment`)}
                                  className={classnames({
                                    "is-invalid": errors.min_sqyds_investment,
                                    "form-control": true,
                                  })}
                                />
                              </FormGroup>
                            ) : (
                                <FormGroup tag={Col} md="4">
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: 14 }}
                                    htmlFor={`no_units`}
                                  >
                                    Enter number of units{" "}
                                    <span className="required-star">*</span>
                                  </Label>
                                  <input
                                    id={`no_units`}
                                    {...register(`no_units`)}
                                  className={classnames({
                                    "is-invalid": errors.no_units,
                                    "form-control": true,
                                  })}
                                />
                              </FormGroup>
                            )}
                            <FormGroup tag={Col} md="4">
                              <Label style={{ fontSize: 14 }}>
                                Select Year{" "}
                                <span className="required-star">*</span>
                              </Label>
                              <select
                                className={classnames({
                                  "is-invalid": errors?.year,
                                  "form-control": true,
                                })}
                                {...register(`year`)}
                                id="year"
                              >
                                <option
                                  selected={currentYear == 2027 ? true : false}
                                >
                                  2027
                                </option>
                                <option
                                  selected={currentYear == 2026 ? true : false}
                                >
                                  2026
                                </option>
                                <option
                                  selected={currentYear == 2025 ? true : false}
                                >
                                  2025
                                </option>
                                <option
                                  selected={currentYear == 2024 ? true : false}
                                >
                                  2024
                                </option>
                                <option
                                  selected={currentYear == 2023 ? true : false}
                                >
                                  2023
                                </option>
                              </select>
                            </FormGroup>
                          </Row>
                          <div className="card-body">
                            {/* <Row>
                              <Col className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="dollor-icon">
                                    <BiDollar />
                                  </div>

                                  <div className="ml-3">
                                    <h3 className="m-0">
                                      {annual_growth_percent?.toFixed(2) || 0} %
                                    </h3>
                                    <small className="text-muted">
                                      Annual Growth of this Asset in {year}
                                    </small>
                                  </div>
                                  {currentYear != year && (
                                    <div className="d-flex align-items-center justify-content-end">
                                      <div>
                                        <div className="d-flex align-items-center justify-content-end">
                                          <small className="text-muted w-25">
                                            Add additional growth of {year}
                                          </small>
                                          <div className="input-group w-50">
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text bg-secondary"
                                                id="basic-addon1"
                                              >
                                                %
                                              </span>
                                            </div>
                                            <input
                                              value={growth_percent_rate}
                                              id={`growth_percent_rate`}
                                              onChange={(e) =>
                                                setgrowth_percent_rate(
                                                  e.target.value
                                                )
                                              }
                                              className={classnames({
                                                "form-control px-2": true,
                                              })}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {property && <div></div>}
                              </Col>
                            </Row> */}
                            <Row className="mt-5">
                              {monthData?.map((m, i) => {
                                return (
                                  <FormGroup tag={Col} md="4" key={i}>
                                    <Label
                                      className="form-label text-dark text-sm"
                                      htmlFor={`sqyds_start_price`}
                                    >
                                      {m.month} {year}
                                    </Label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text bg-secondary"
                                          id="basic-addon1"
                                        >
                                          KES
                                        </span>
                                      </div>
                                      {/* <input
                                                                            key={m.month}
                                                                            name={`months[${i}]`}
                                                                            {...register(`months.${i}`)}
                                                                            className={`form-control px-2`}
                                                                        /> */}
                                      <input
                                        disabled={disbale_month}
                                        className={`form-control px-2`}
                                        onChange={(e) => {
                                          const firstName = e.target.value;
                                          setMonthData((currentPeople) =>
                                            produce(currentPeople, (v) => {
                                              v[i].price = firstName;
                                            })
                                          );
                                        }}
                                        value={m.price}
                                      />
                                    </div>
                                  </FormGroup>
                                );
                              })}
                            </Row>
                          </div>
                        </>
                      ) : (
                        // show only when plot is selected
                        selectedBlockId == null && <BlankView />
                      )
                    }
                    {selectedBlockId && (
                      <Row className="my-3">
                        {plotStatus ? (
                          <>
                            <Col className="d-flex justify-content-start">
                              <Button
                                color="primary"
                                onClick={() => clearProperty()}
                              >
                                <span className="align-middle d-sm-inline-block d-none">
                                  Erase Property
                                </span>
                              </Button>
                            </Col>
                            <Col className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="btn-prev"
                                type="submit"
                              >
                                <i className="fa fa-save align-middle mr-sm-2 mr-0"></i>
                                <span className="align-middle d-sm-inline-block d-none">
                                  {"Submit"}
                                </span>
                              </Button>
                            </Col>
                          </>
                        ) : (
                          selectedPlot?.status == "Active" && (
                            <Col className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="btn-prev"
                                type="submit"
                              >
                                <i className="fa fa-save align-middle mr-sm-2 mr-0"></i>
                                <span className="align-middle d-sm-inline-block d-none">
                                  {"De-Activate Plot"}
                                </span>
                              </Button>
                            </Col>
                          )
                        )}
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          {property && property.svg_elements && (
            <Row>
              <Col>
                <small className="text-muted">
                  {plotLeft == 0
                    ? ""
                    : `Please complete ${plotLeft} plots to move further`}
                </small>
              </Col>
            </Row>
          )}
        </>
      )}
      {/*
            Asset Categoty-: Tower
            1. Residencial  Flow
            2. Holiday Homes
             */}
      {checkProperty == 2 && (
        <>
          <Row>
            <Col>
              <FormGroup className="d-flex justify-content-between">
                <div>
                  <h5 className="mb-0 text-muted">You can add new Floor</h5>
                </div>
                <Button color="primary" onClick={() => setIsModalOpen(true)}>
                  Add New Floor
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onBlockSubmit)}>
            <Row>
              <Col lg="2">
                {selectedBlockId && (
                  <Row>
                    <Col lg="12">
                      <small>Asset Status</small>
                      <div className="custom-control custom-switch">
                        {/* check if toggle perform on selected plot/block */}
                        <input
                          type="checkbox"
                          name="plotStatus"
                          {...register(`status`, {
                            onChange: (e) => onPlotStatusChange(e),
                          })}
                          className="custom-control-input"
                          id="plot-status"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="plot-status"
                        ></label>
                      </div>
                    </Col>
                    {plotId ? (
                      <Col lg="12" className="my-3">
                        {property?.property_type_id == 1 && (
                          <small className="text-sm">Plot ID</small>
                        )}
                        {(property?.property_type_id == 2 ||
                          property?.property_type_id == 4) && (
                            <small className="text-sm">Flat ID</small>
                          )}
                        <div className="text-sm font-weight-bold">{plotId}</div>
                      </Col>
                    ) : null}
                    {/* {plotStatus && (
                                            <>
                                                <Col lg='12'>
                                                    <small>Fractional ?</small>
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox"
                                                            disabled={true}
                                                            {...register(`investment_type`, { onChange: (e) => onPlotInvestmentTypeChange(e) })}
                                                            className="custom-control-input" id="plot-investment" />
                                                        <label className="custom-control-label" htmlFor="plot-investment"></label>
                                                    </div>
                                                </Col>
                                            </>
                                        )} */}
                  </Row>
                )}
              </Col>
              <Col>
                <div className="container">
                  {plotsResi?.map((subArray, index) => {
                    const subArrayWidth = calculateWidth(subArray.length);
                    return (
                      <div
                        className="row d-flex flex-row mt-3"
                        style={{
                          margin: 2,
                        }}
                        key={index}
                      >
                        <p className="pr-2 pt-2">{subArray?.[0]?.uuid}</p>
                        {subArray?.map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            onClick={() => {
                              if (!item?.plot_name) {
                                onClickResidensialPlot(item);
                              } else {
                                toast.error(
                                  "This is Static Asset,Select Dynemic Asset."
                                );
                              }
                            }}
                            className="border rounded"
                            style={{
                              backgroundColor:
                                item?.id == plotId
                                  ? "#725AEC"
                                  : item?.status == "Active"
                                    ? "#FFF"
                                    : item?.status == "Fully Invested"
                                      ? "#e3e1e3" //#FF7070
                                      : "#e3e1e3",
                              border: "1px solid red",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 2,
                              margin: calculateMargin(subArray?.length),
                              width: `${subArrayWidth}%`,
                              cursor: "pointer",
                            }}
                          >
                            <p
                              style={{
                                color: item?.id == plotId ? "#FFF" : "#000",
                              }}
                            >
                              {item?.plot_name
                                ? item?.plot_name
                                : item?.block_id}
                            </p>
                          </div>
                        ))}
                        {/* <Button
                                                    onClick={() => console.log("Hello")}
                                                    className="btn btn-sm btn-link text-primary bg-transparent border-0"
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </Button> */}
                        <Button
                          onClick={() => onFloorSubmit(subArray?.[0])}
                          className="btn btn-sm btn-link text-danger bg-transparent border-0"
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="card border-secondary p-3">
                  <Row className="py-3 border-bottom">
                    <Col className="d-flex align-items-center">
                      <div className="mr-3 text-sm">
                        <span className="dot text-secondary">.</span> Available
                      </div>
                      <div className="mr-3 text-sm">
                        <span className="dot text-gray">.</span> Inactive
                      </div>
                      {/* <div className='mr-3 text-sm'><span className="dot text-success">.</span> Co-Ownership</div> */}
                      {/* <div className='mr-3 text-sm'><span className="dot" style={{ color: '#FF9F43' }}>.</span>Fractional</div> */}
                      <div className="mr-3 text-sm">
                        <span className="dot text-primary">.</span> Selected
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {plotStatus && selectedBlockId != null ? (
              <Row>
                <Col className="">
                  <div className="card border-secondary p-3">
                    <div>
                      <Row className="my-3">
                        <>
                          <FormGroup tag={Col} md="4">
                            <Label
                              className="form-label"
                              style={{ fontSize: 14 }}
                              htmlFor={`no_sqyds`}
                            >
                              Total number of Tokens/sqft
                              <span className="required-star">*</span>
                            </Label>
                            <input
                              id={`no_sqyds`}
                              disabled={disable}
                              {...register(`no_sqyds`)}
                              className={classnames({
                                "is-invalid": errors.no_sqyds,
                                "form-control": true,
                              })}
                            />
                          </FormGroup>
                          <FormGroup tag={Col} md="4">
                            <Label
                              className="form-label"
                              style={{ fontSize: 14 }}
                              htmlFor={`min_sqyds_investment`}
                            >
                              Minimum Tokens/sqft to invest
                              <span className="required-star">*</span>
                            </Label>
                            <input
                              id={`min_sqyds_investment`}
                              {...register(`min_sqyds_investment`)}
                              className={classnames({
                                "is-invalid": errors.min_sqyds_investment,
                                "form-control": true,
                              })}
                            />
                          </FormGroup>

                          {/* <FormGroup tag={Col} md="4">
                              <Label style={{ fontSize: 14 }}>
                                Select Year{" "}
                                <span className="required-star">*</span>
                              </Label>
                              <select
                                className={classnames({
                                  "is-invalid": errors?.year,
                                  "form-control": true,
                                })}
                                {...register(`year`)}
                                id="year"
                              >
                                <option>Select Year</option>
                                <option
                                  selected={currentYear == 2027 ? true : false}
                                >
                                  2027
                                </option>
                                <option
                                  selected={currentYear == 2026 ? true : false}
                                >
                                  2026
                                </option>
                                <option
                                  selected={currentYear == 2025 ? true : false}
                                >
                                  2025
                                </option>
                                <option
                                  selected={currentYear == 2024 ? true : false}
                                >
                                  2024
                                </option>
                                <option
                                  selected={currentYear == 2023 ? true : false}
                                >
                                  2023
                                </option>
                              </select>
                            </FormGroup> */}
                        </>
                        {property.stage == "Under Construction" && (
                          <>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`max_sqyds_investment`}
                              >
                                Maximum Tokens/sqft to invest
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`max_sqyds_investment`}
                                {...register(`max_sqyds_investment`)}
                                className={classnames({
                                  "is-invalid": errors.max_sqyds_investment,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`base_price`}
                              >
                                Token/sqft price
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`base_price`}
                                {...register(`base_price`)}
                                className={classnames({
                                  "is-invalid": errors.base_price,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`min_maturity`}
                              >
                                Lock In Period
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`min_maturity`}
                                {...register(`min_maturity`)}
                                className={classnames({
                                  "is-invalid": errors?.min_maturity,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`start_with`}
                              >
                                Starts With Price
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`start_with`}
                                {...register(`start_with`)}
                                className={classnames({
                                  "is-invalid": errors?.start_with,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`current_year_growth_percent`}
                              >
                                Total Asset IRR  %
                                <span className="required-star">*</span>
                              </Label>
                              <input
                                id={`current_year_growth_percent`}
                                {...register(`current_year_growth_percent`)}
                                className={classnames({
                                  "is-invalid": errors?.current_year_growth_percent,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                          </>
                        )}
                        {(property?.property_type_id == 2 ||
                          property?.property_type_id == 4) && (
                          <>
                            <FormGroup tag={Col} md="4">
                              <Label
                                className="form-label"
                                style={{ fontSize: 14 }}
                                htmlFor={`house_type`}
                              >
                                Asset type
                              </Label>
                              <input
                                placeholder="2,3 BHK"
                                id={`house_type`}
                                {...register(`house_type`)}
                                className={classnames({
                                  "is-invalid": errors.house_type,
                                  "form-control": true,
                                })}
                              />
                            </FormGroup>
                            </>
                          )}
                      </Row>
                      <div className="">
                        {/* <Row>
                          <Col className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dollor-icon">
                                <BiDollar />
                              </div>

                              <div className="ml-3">
                                <h3 className="m-0">
                                  {annual_growth_percent?.toFixed(2) || 0} %
                                </h3>
                                <small className="text-muted">
                                  Annual Growth of this Asset in {year}
                                </small>
                              </div>
                              {currentYear != year && (
                                <div className="d-flex align-items-center justify-content-end">
                                  <div>
                                    <div className="d-flex align-items-center justify-content-end">
                                      <small className="text-muted w-25">
                                        Add additional growth of {year}
                                      </small>
                                      <div className="input-group w-50">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text bg-secondary"
                                            id="basic-addon1"
                                          >
                                            %
                                          </span>
                                        </div>
                                        <input
                                          value={growth_percent_rate}
                                          id={`growth_percent_rate`}
                                          onChange={(e) =>
                                            setgrowth_percent_rate(
                                              e.target.value
                                            )
                                          }
                                          className={classnames({
                                            "form-control px-2": true,
                                          })}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row> */}
                        <Row className="mt-5">
                          {property.stage == "Under Construction"
                            ? null
                            : monthData?.map((m, i) => {
                              return (
                                <FormGroup tag={Col} md="4" key={i}>
                                  <Label
                                    className="form-label text-dark text-sm"
                                    htmlFor={`sqyds_start_price`}
                                  >
                                    {m.month} {year}
                                  </Label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text bg-secondary"
                                        id="basic-addon1"
                                      >
                                        KES
                                      </span>
                                    </div>
                                    <input
                                      disabled={disbale_month}
                                      className={`form-control px-2`}
                                      onChange={(e) => {
                                        const firstName = e.target.value;
                                        setMonthData((currentPeople) =>
                                          produce(currentPeople, (v) => {
                                            v[i].price = firstName;
                                          })
                                        );
                                      }}
                                      value={m.price}
                                    />
                                  </div>
                                </FormGroup>
                              );
                            })}
                        </Row>
                      </div>
                      <Row className="my-3">
                        <Col className="d-flex  justify-content-start">
                          <Button
                            color="primary"
                            onClick={() => clearProperty()}
                          >
                            <span className="align-middle d-sm-inline-block d-none">
                              Erase Property
                            </span>
                          </Button>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button
                            color="primary"
                            className="btn-prev"
                            type="submit"
                          >
                            <i className="fa fa-save align-middle mr-sm-2 mr-0"></i>
                            <span className="align-middle d-sm-inline-block d-none">
                              Submit
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              selectedBlockId == null && <BlankView />
            )}
          </Form>
        </>
      )}
      {/*
            Asset Categoty-: Villa
            1. Residencial Flow (Villa)
            2. Holiday Homes (Villa)
            3. Commercial (Tower or Villa)
            4. Land Parcel (Individual Land)
            */}
      {checkProperty == 3 && (
        <>
          <Form onSubmit={handleSubmit(onBlockSubmit)}>
            <Row className="rz-card-border" style={{ borderRadius: "6px" }}>
              <Col>
                <div className="card border-secondary p-3">
                  <div>
                    <Row className="my-3">
                      <FormGroup tag={Col} md="4">
                        <Label
                          className="form-label"
                          style={{ fontSize: 14 }}
                        >
                          Total number of Tokens/sqft{" "}
                          <span className="required-star">*</span>
                        </Label>
                        <input
                          id={`no_sqyds`}
                          disabled={disable}
                          {...register(`no_sqyds`)}
                          className={classnames({
                            "is-invalid": errors.no_sqyds,
                            "form-control": true,
                          })}
                        />
                      </FormGroup>
                      <FormGroup tag={Col} md="4">
                        <Label
                          className="form-label"
                          style={{ fontSize: 14 }}
                          htmlFor={`min_sqyds_investment`}
                        >
                          Minimum Tokens to invest{" "}
                          <span className="required-star">*</span>
                        </Label>
                        <input
                          id={`min_sqyds_investment`}
                          {...register(`min_sqyds_investment`)}
                          className={classnames({
                            "is-invalid": errors.min_sqyds_investment,
                            "form-control": true,
                          })}
                        />
                      </FormGroup>
                      <FormGroup tag={Col} md="4">
                        <Label
                          className="form-label"
                          style={{ fontSize: 14 }}
                        >
                          {
                            property?.share_type == "NCD" ?
                              "Estimated IRR (in %)" :
                              " Total Asset IRR  %"
                          }
                          <span className="required-star">*</span>
                        </Label>
                        <input
                          className={classnames({
                            "is-invalid": errors?.current_year_growth_percent,
                            "form-control": true,
                          })}
                          {...register("current_year_growth_percent")}
                          id="current_year_growth_percent"
                        />
                      </FormGroup>
                      <FormGroup tag={Col} md="4">
                        <Label
                          className="form-label"
                          style={{ fontSize: 14 }}
                          htmlFor={`start_with`}
                        >
                          Start With price
                          <span className="required-star">*</span>
                        </Label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text bg-secondary"
                              id="basic-addon1"
                            >
                              KES
                            </span>
                          </div>
                          <input
                            id={`start_with`}
                            {...register(`start_with`)}
                            className={classnames({
                              "is-invalid": errors.start_with,
                              "form-control": true,
                            })}
                          />
                        </div>
                      </FormGroup>
                      {property.share_type == "NCD" ? (
                        <>
                          <FormGroup tag={Col} md="4">
                            <Label
                              className="form-label"
                              style={{ fontSize: 14 }}
                            >
                              Coupon Rate (in %){" "}
                              <span className="required-star">*</span>
                            </Label>
                            <input
                              {...register("fixed_return")}
                              id="fixed_return"
                              className={classnames({ "form-control": true })}
                            />
                          </FormGroup>
                          <FormGroup tag={Col} md="4">
                            <Label
                              className="form-label"
                              style={{ fontSize: 14 }}
                            >
                              Security Cover{" "}
                              <span className="required-star">*</span>
                            </Label>
                            <input
                              {...register("security_x_cover")}
                              id="security_x_cover"
                              className={classnames({ "form-control": true })}
                            />
                          </FormGroup>
                        </>
                      ) : (
                        <>
                            {property.stage == "Under Construction" && (
                              <>
                                <FormGroup tag={Col} md="4">
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: 14 }}
                                    htmlFor={`max_sqyds_investment`}
                                  >
                                    Maximum Tokens/sqft to invest
                                    <span className="required-star">*</span>
                                  </Label>
                                  <input
                                    id={`max_sqyds_investment`}
                                    {...register(`max_sqyds_investment`)}
                                    className={classnames({
                                      "is-invalid": errors.max_sqyds_investment,
                                      "form-control": true,
                                    })}
                                  />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: 14 }}
                                    htmlFor={`min_maturity`}
                                  >
                                    Lock In Period
                                    <span className="required-star">*</span>
                                  </Label>
                                  <input
                                    id={`min_maturity`}
                                    {...register(`min_maturity`)}
                                    className={classnames({
                                      "is-invalid": errors?.min_maturity,
                                      "form-control": true,
                                    })}
                                  />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                  <Label
                                    className="form-label"
                                    htmlFor={`property_type`}
                                  >
                                    Investment Risk Analysis{" "}
                                    <span className="required-star">*</span>
                                  </Label>
                                  <select
                                    className={classnames({
                                      "is-invalid": errors.risk_analysis,
                                      "form-control": true,
                                    })}
                                    {...register(`risk_analysis`)}
                                    id="risk_analysis"
                                  >
                                    <option value="">Select Risk Type</option>
                                    <option value={"Very Low"}>Very Low</option>
                                    <option value={"Low"}>Low</option>
                                    <option value={"Moderate"}>Moderate</option>
                                    <option value={"Medium"}>Medium</option>
                                    <option value={"High"}>High</option>
                                    <option value={"Very High"}>Very High</option>
                                  </select>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                  <Label htmlFor="about-property">
                                    Investment Performance Description{" "}
                                    <span className="required-star">*</span>
                                  </Label>
                                  <textarea
                                    className={classnames({
                                      "is-invalid":
                                        errors?.investment_performance_description,
                                      "form-control": true,
                                    })}
                                    {...register(
                                      `investment_performance_description`
                                    )}
                                    id="about-property"
                                    rows="4"
                                    placeholder="Enter Description"
                                  />
                                </FormGroup>
                                <Col sm={12}>
                                  {yearsFields.map((field, index) => (
                                    <Row
                                      style={{ position: "relative" }}
                                      className="mt-5 px-2 pb-2 pt-3 year-wrapper"
                                      key={index}
                                    >
                                      <div
                                        onClick={() => removeYear(index)}
                                        style={{
                                          position: "absolute",
                                          top: 10,
                                          right: 10,
                                          zIndex: 100,
                                          cursor: "pointer",
                                          color: "#82868B"
                                        }}
                                      >
                                        <FaRegTrashAlt style={{ cursor: "pointer" }} />
                                      </div>
                                      <Col className="mb-1" sm={12}>
                                        <h1 className="rz-heading rz-text-18">
                                          Year {index + 1}
                                        </h1>
                                      </Col>
                                    <FormGroup tag={Col} md="6">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Year
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(`years.${index}.year`, {
                                          required: true,
                                        })}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]?.year,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Year"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="6">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Month
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(`years.${index}.month`, {
                                          required: true,
                                        })}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]?.month,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Month"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Asset Est.IRR %
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(`years.${index}.irr`, {
                                          required: true,
                                        })}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]?.irr,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Growth %"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        MF Est.Growth %
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}.mutual_fund_percent`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.mutual_fund_percent,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Growth %"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        FD/AAA Bonds Est.Gw %
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}.fixed_deposit_percent`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.fixed_deposit_percent,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Growth %"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Gold Est.Growth %
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}.share_market_percent`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.share_market_percent,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Growth %"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Asset Est.Value
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(`years.${index}.metadata.asset_value`, {
                                          required: true,
                                        })}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]?.metadata?.asset_value,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Est.Value"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        MF Est.Value
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}.metadata.mutual_fund_value`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.metadata?.mutual_fund_value,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Est.Value"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        FD/AAA Bonds Est.Value
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}.metadata.fixed_deposit_value`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.metadata?.fixed_deposit_value,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Est.Value"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="3">
                                      <Label
                                        className="form-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Gold Est.Value
                                        <span className="required-star">*</span>
                                      </Label>
                                      <input
                                        {...register(
                                          `years.${index}..metadata.share_market_value`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        className={classNames({
                                          "is-invalid":
                                            errors?.years?.[index]
                                              ?.metadata?.share_market_value,
                                          "form-control": true,
                                        })}
                                        placeholder="Enter Est.Value"
                                        defaultValue={field?.value || ""}
                                      />
                                    </FormGroup>
                                  </Row>
                                ))}
                                </Col>
                                <Col
                                  className="d-flex mt-4 justify-content-center align-items-center"
                                  sm={12}
                                >
                                  <div>
                                    <HiOutlinePlusCircle
                                      onClick={() => appendYear({})}
                                      style={{
                                        fontSize: "25px",
                                        color: "#7367F0",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                            {(property?.property_type_id == 2 ||
                              property?.property_type_id == 4) && (
                                <>
                                <FormGroup tag={Col} md="4">
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: 14 }}
                                    htmlFor={`house_type`}
                                  >
                                    Asset type
                                  </Label>
                                  <input
                                    placeholder="2,3 BHK"
                                    id={`house_type`}
                                    {...register(`house_type`)}
                                    className={classnames({
                                      "is-invalid": errors.house_type,
                                      "form-control": true,
                                    })}
                                  />
                                </FormGroup>
                                </>
                              )}
                          </>
                      )}
                    </Row>
                    {property.share_type !== "NCD" && (
                      <div className="">
                        {/* <Row>
                          <Col className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dollor-icon">
                                <BiDollar />
                              </div>

                              <div className="ml-3">
                                <h3 className="m-0">
                                  {annual_growth_percent?.toFixed(2) || 0} %
                                </h3>
                                <small className="text-muted">
                                  Annual Growth of this Asset in {year}
                                </small>
                              </div>
                              {currentYear != year && (
                                <div className="d-flex align-items-center justify-content-end">
                                  <div>
                                    <div className="d-flex align-items-center justify-content-end">
                                      <small className="text-muted w-25">
                                        Add additional growth of {year}
                                      </small>
                                      <div className="input-group w-50">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text bg-secondary"
                                            id="basic-addon1"
                                          >
                                            %
                                          </span>
                                        </div>
                                        <input
                                          value={growth_percent_rate}
                                          id={`growth_percent_rate`}
                                          onChange={(e) =>
                                            setgrowth_percent_rate(
                                              e.target.value
                                            )
                                          }
                                          className={classnames({
                                            "form-control px-2": true,
                                          })}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row> */}
                        <Row className="mt-5">
                          {property.stage == "Under Construction"
                            ? null
                            : monthData?.map((m, i) => {
                              return (
                                <FormGroup tag={Col} md="4" key={i}>
                                  <Label
                                    className="form-label text-dark text-sm"
                                    htmlFor={`sqyds_start_price`}
                                  >
                                    {m.month} {year}
                                  </Label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text bg-secondary"
                                        id="basic-addon1"
                                      >
                                        KES
                                      </span>
                                    </div>
                                    <input
                                      disabled={disbale_month}
                                      className={`form-control px-2`}
                                      onChange={(e) => {
                                        const firstName = e.target.value;
                                        setMonthData((currentPeople) =>
                                          produce(currentPeople, (v) => {
                                            v[i].price = firstName;
                                          })
                                        );
                                      }}
                                      value={m.price}
                                    />
                                  </div>
                                </FormGroup>
                              );
                            })}
                        </Row>
                      </div>
                    )}
                    {
                      <Row className="my-3 justify-content-end pt-4">
                        <Col className="col-12 justify-content-end d-flex ">
                          <div>
                            {property?.share_type != "NCD" && (
                              <Button
                                className="rz-button-outline-primary"
                                onClick={() => clearProperty()}
                              >
                                <MdRefresh style={{ fontSize: "18px" }} />
                                <span className="align-middle d-sm-inline-block d-none">
                                  Clear data
                                </span>
                              </Button>
                            )}
                            <Button
                              className="rz-button-primary ml-3"
                              type="submit"
                            >
                              <HiOutlineDocumentText
                                style={{ fontSize: "18px" }}
                              />
                              <span className="align-middle d-sm-inline-block d-none">
                                {property?.share_type == "NCD"
                                  ? "Submit"
                                  : "Save changes"}
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {propertyPlot && (
        <Row className="mt-5">
          <Col className="px-0">
            <div className="card-header px-0 border-0 py-3">
              <h3 className="all-page-heading rz-text-18">
                {property?.share_type == "NCD"
                  ? "Selected asset’s pricing"
                  : asset_type == 1
                    ? "Selected plot’s pricing"
                    : "Selected Asset price"}
              </h3>
            </div>
          </Col>
          <Col
            className="rz-card-border"
            style={{ borderRadius: "6px" }}
            lg="12"
          >
            <div className="card text-left">
              <div className="card-body rz-shadow-none py-3">
                <Row>
                  <Col sm={6} className="d-flex align-items-center">
                    <div className="dollor-icon">
                      <BiDollar />
                    </div>
                    <div className="ml-3">
                      <h3 className="m-0">
                        {moneyFormat(
                          parseFloat(propertyPlot.no_sqyds || 0) *
                          current_month_price
                        )}
                      </h3>
                      <small className="text-muted">Asset Cost</small>
                    </div>
                  </Col>
                  {property?.share_type != "NCD" && (
                    <Col sm={6} className="d-flex align-items-center">
                      <div className="dollor-icon">
                        <AiOutlinePercentage />
                      </div>
                      <div className="ml-3">
                        <h3 className="m-0">{property?.current_year_growth_percent}</h3>
                        <small className="text-muted">
                          Total Asset IRR %
                        </small>
                      </div>
                    </Col>
                  )}
                  {/* <Col className="d-flex align-items-center">
                    <div className="user-icon">
                      <BiUser />
                    </div>
                    <div className="ml-3">
                      <h3 className="m-0">
                        {moneyFormat(
                          parseFloat(propertyPlot.no_sqyds || 0) *
                            current_month_price
                        )}
                      </h3>
                      <small className="text-muted">
                        Total Asset Amount In {year}
                      </small>
                    </div>
                  </Col>
                  {propertyPlot?.current_day_price &&
                                            <Col className='d-flex align-items-center'>
                                                <span className='icon-round bg-gradient-success text-white'>$</span>
                                                <div className='ml-3'>
                                                    <h3 className='m-0'>{moneyFormat(parseFloat(propertyPlot.current_day_price || 0))}</h3>
                                                    <small className='text-muted'>Today's buy Price</small>
                                                </div>
                    </Col>} */}
                  {/* {!isFractional && (
                                    <Col className='d-flex align-items-center'>
                                        <span className='icon-round bg-gradient-success text-white'>$</span>
                                        <div className='ml-3'>
                                            <h3 className='m-0'>{moneyFormat(propertyPlot?.current_month_unit_price)}</h3>
                                            <small className='text-muted'>Cost Per Unit</small>
                                        </div>
                                    </Col>
                                )}
                                {!isFractional && (
                                    <Col className='d-flex align-items-center'>
                                        <span className='icon-round bg-gradient-success text-white'>/</span>
                                        <div className='ml-3'>
                                            <h3 className='m-0'>{propertyPlot?.no_sqyds / propertyPlot?.no_units || '1'} Sqyds</h3>
                                            <small className='text-muted'>Splitted for each unit</small>
                                        </div>
                                    </Col>
                                )} */}
                </Row>
                <Row className="mt-4">
                  {/* {propertyPlot?.total_sqyds_booked &&
                                    <Col className='d-flex align-items-center'>
                                        <span className='icon-round bg-gradient-success text-white'>$</span>
                                        <div className='ml-3'>
                                            <h3 className='m-0'>
                                                {(parseFloat(propertyPlot.total_sqyds_booked || 0)
                                                - parseFloat(propertyPlot.total_sqyds_sold || 0)).toFixed(2)}
                                            </h3>
                                            <small className='text-muted'>Total Booked Sqyds</small>
                                        </div>
                                    </Col>} */}
                  {/* {propertyPlot?.plot_invested_percent &&
                                    <Col className='d-flex align-items-center'>
                                        <span className='icon-round bg-gradient-success text-white'>$</span>
                                        <div className='ml-3'>
                                            <h3 className='m-0'>{(parseFloat(propertyPlot.plot_invested_percent || 0))?.toFixed(2)}%</h3>
                                            <small className='text-muted'>Asset Invested Percent</small>
                                        </div>
                                    </Col>} */}
                  {/* {(propertyPlot?.total_sqyds_sold || propertyPlot?.total_sqyds_sold == 0) &&
                                    <Col className='d-flex align-items-center'>
                                        <span className='icon-round bg-gradient-success text-white'>$</span>
                                        <div className='ml-3'>
                                            <h3 className='m-0'>{(parseFloat(propertyPlot.total_sqyds_sold))?.toFixed(2)}</h3>
                                            <small className='text-muted'>Total Sold Sqyds</small>
                                        </div>
                                    </Col>} */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <div className="mt-5 align-item-center d-flex justify-content-between">
        <div>
          <Button
            type="button"
            color="success"
            onClick={() => {
              if (property?.stage == "Under Construction") {
                parentStepper?.next();
                return;
              }
              stepper.next()
            }}
            className="btn-next"
          >
            <span className="align-middle d-sm-inline-block d-none">Skip</span>
            <i className="fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0"></i>
          </Button>
        </div>
        <div className="d-flex align-item-center justify-content-end">
          <Link to="/admin/property">
            <Button type="button" className="rz-button-outline-primary">
              Cancel
            </Button>
          </Link>
          <Button
            type="button"
            className="btn-prev ml-3 mr-2 d-flex rz-button-outline-primary"
            onClick={() => stepper.previous()}
          >
            <BsArrowLeftShort style={{ fontSize: "22px" }} />
            <span className="align-middle d-sm-inline-block d-none">Back</span>
          </Button>
          <Button
            type="button"
            onClick={onSaveNextClick}
            className="btn-next rz-button-primary"
          >
            <BsArrowRightShort style={{ fontSize: "22px" }} />
            <span className="align-middle d-sm-inline-block d-none">Next</span>
          </Button>
        </div>
      </div>
      <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
        <Form>
          <div className="px-4 pt-4 d-flex justify-content-between">
            <div>
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Floor
              </h5>
            </div>
            <div>
              <Button
                type="button"
                className="btn-close btn-link bg-white"
                onClick={() => setIsModalOpen(false)}
              >
                <i className="fas fa-times    "></i>
              </Button>
            </div>
          </div>
          <ModalBody>
            <FormGroup>
              <Label className="form-label" htmlFor={`question`}>
                Floor No.
              </Label>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="eg. 1"
                {...register2("uuid", {})}
              />
            </FormGroup>
            <FormGroup>
              <Label className="form-label" htmlFor={`property_resident_type`}>
                Select Video Type <span className="required-star">*</span>
              </Label>
              <select
                onChange={(e) => setResidentPlotType(e.target.value)}
                id="property_resident_type"
                className={classnames({
                  "form-control": true,
                })}
              >
                <option value="">
                  Select Floor Type <span className="required-star">*</span>
                </option>
                <option selected={"static" == residentPlotType} value="static">
                  Static
                </option>
                <option
                  selected={"dynemic" == residentPlotType}
                  value="dynemic"
                >
                  Dynemic
                </option>
              </select>
            </FormGroup>
            {residentPlotType == "static" && (
              <FormGroup>
                <Label className="form-label" htmlFor={`plot_name`}>
                  Floor Name
                </Label>
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="eg. Parking"
                  {...register2("plot_name", {})}
                />
              </FormGroup>
            )}
            {residentPlotType == "dynemic" && (
              <FormGroup>
                <Label className="form-label" htmlFor={`description`}>
                  Number of Assert
                </Label>
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="2"
                  {...register2("number_of_assert", {})}
                />
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>

            <Button onClick={() => onFloorSubmit()} color="primary">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default PlotDetails;
