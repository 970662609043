import React from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify';
import { useMutateaddCompanyBankAccount } from 'hooks/useCompany';
import { QueryClient } from 'react-query';
import { api } from './../../helpers';
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Link } from 'react-router-dom';
export default function BankDetails({ stepper, type, company }) {
    const [name, setName] = React.useState("");
    const [accType, setAccType] = React.useState("");
    const [number1, setNumber1] = React.useState("");
    const [number2, setNumber2] = React.useState("");
    const [ifsc, setIfsc] = React.useState("");
    const [swift, setSwift] = React.useState("");
    const [other, setOther] = React.useState("")
    const [book, setBook] = React.useState("");
    const [attachement, setAttachement] = React.useState("")
    const queryClient = new QueryClient()

    React.useEffect(() => {
        if (company) {
            if (company?.LLPBankAccounts?.length > 0) {
                let data = company?.LLPBankAccounts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
                setName(data?.account_holder_name)
                setAccType(data?.account_type)
                setNumber1(data?.account_number)
                setNumber2(data?.account_number)
                setIfsc(data?.ifsc)
                setSwift(data?.swift_number)
                setOther(data?.other_instructions)
                setAttachement(data?.passbook_photo)
            }
        }
    }, [company])
    const { mutate: addCompnayBankAccount } = useMutateaddCompanyBankAccount()
    async function handleSubmit() {
        if (name && number1 && number2) {
            if (number1 == number2) {
                let data = {
                    id: company?.id,
                    account_holder_name: name,
                    account_type: accType == undefined || accType == ''
                        ? "current" : accType,
                    account_number: number2,
                    ifsc: ifsc,
                    swift_number: swift,
                    other_instructions: other,
                    passbook_photo: attachement
                }
                if (book) {
                    const formDataNew = new FormData();
                    formDataNew.append("file", book)
                    try {
                        const res = await api.post("/file",
                            formDataNew
                        );
                        data.passbook_photo = res?.url
                    } catch (ex) {
                        console.log(ex);
                        return;
                    }
                }
                if (company?.LLPBankAccounts?.length <= 0) {
                    await addCompnayBankAccount(data, {
                        onSuccess: (res) => {
                            toast.success('Bank Account Added Successfully')
                            stepper.next();
                            queryClient.invalidateQueries(`user_company_${company?.id}`)
                        },
                        onError: (err) => {
                            toast.error(err)
                        },
                        onFailure: (err) => {
                            console.log("err", err)
                            toast.error('Something went wrong')
                        }
                    })
                }
                else {
                    await addCompnayBankAccount(data, {
                        onSuccess: (res) => {
                            stepper.next();
                            toast.success('Company Updated Successfully')
                            queryClient.invalidateQueries(`user_company_${company?.id}`)
                        },
                        onError: (err) => {
                            toast.error(err)
                        },
                        onFailure: (err) => {
                            console.log("err", err)
                            toast.error('Something went wrong')
                        }
                    })
                }

            } else {
                toast.error("Account number doesn't match")
            }
        } else {
            if (book == undefined)
                toast.error("please select passbook copy")
        }
    }

    return (
        <>
            <div className='content-header'>
                <h3 className='mb-2 rz-heading rz-text-18'>Bank Details</h3>
            </div>
            <div className='row'>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Account Holder Name * </label>
                    <input
                        placeholder='eg. The Lake view Park, Brigade '
                        className='form-control'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className={`col-md-6 form-group`}>
                    <label className='form-label'>Account Type * </label>
                    <select className='form-control'
                        value={accType} onChange={e => setAccType(e.target.value)}>
                        <option value=''>Select Account Type</option>
                        <option value="Current">Current</option>
                        <option value="Saving">Saving</option>
                    </select>
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Account Number * </label>
                    <input type="password" className='form-control' value={number1} onChange={e => setNumber1(e.target.value)} />
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Confirm Account Number * </label>
                    <input type="text" className='form-control' value={number2} onChange={e => setNumber2(e.target.value)} />
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Bank Name * </label>
                    <input type="text" className='form-control' value={ifsc} onChange={e => setIfsc(e.target.value)} />
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Bank Branch</label>
                    <input type="text" className='form-control' value={swift} onChange={e => setSwift(e.target.value)} />
                </div>

                <div className='col-md-12 mb-2'>
                    <label className='form-label'>Other instructions</label>
                    <textarea cols={10} rows={10} className="form-control" value={other} onChange={e => setOther(e.target.value)}></textarea>
                </div>

                {/* <div className='col-md-6 form-group'>
                    <label className='form-label'>Upload Account book copy * </label>
                    <input className='form-control' type="file" onChange={e => setBook(e.target.files[0])} />
                    {attachement && <a href={`${attachement}`} target="_blank">View Attchement</a>}
                </div> */}

            </div>
            <div className='mt-5 align-item-center d-flex justify-content-between'>
                <div>
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                </div>
                <div className='d-flex align-item-center justify-content-end'>
                    <Link to='/admin/company'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>
                    <Button color='primary' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>
                    <Button type='button' onClick={() => handleSubmit()} className='btn-next rz-button-primary'>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>

                    </Button>
                </div>
            </div>
        </>
    )
}
