import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, Form, Row } from "reactstrap";
import { configActions } from "redux/actions";
import { useHistory } from 'react-router-dom';

const AddEditLegalChecklist = () => {
    const history = useHistory();
    const country = useSelector((state) => state.getCountryReducer.countryStates);
    const { data, response } = useSelector(
        (state) => state.addLegalChecklistReducer
    );
    const { propertyLegals } = useSelector(
        (state) => state.getLegalChecklistReducer
    );
    const dispatch = useDispatch();
    const [legalChecklist, setLegalChecklist] = useState([]);
    const [selectedLegalChecklist, setSelectedLegalChecklist] = useState({});
    const [showableChecklist, setShowableChecklist] = useState([]);

    const [states, setStates] = useState([]);
    const [stateName, setStateName] = useState("");
    const [stateId, setStateId] = useState();
    const [inputValue, setInputValue] = useState("");
    const [propertyLegalType, setPropertyLegalType] = useState("");
    const { id } = useParams();

    useEffect(() => {
        dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
        dispatch(configActions.getPropertyLegals());
    }, []);

    useEffect(() => {
        if (country?.states) {
            setStates(country.states);
        }
    }, [country]);

    useEffect(() => {
        if (propertyLegals?.length > 0) {
            const filterdPropertyLegal = propertyLegals.find((property) => {
                return property.id == id;
            });

            setSelectedLegalChecklist(filterdPropertyLegal);
            setPropertyLegalType(filterdPropertyLegal.name);
            setLegalChecklist(propertyLegals);
        }
    }, [propertyLegals]);

    const handleStateChange = (e) => {
        if (e.target.value == "select One") {
            setStateName("");
        } else {
            setStateName(e.target.value);
        }

        const filteredState = states.find((state) => {
            return state.name == e.target.value;
        });
        setStateId(filteredState?.id);

        const filteredChecklist = selectedLegalChecklist?.propertyLegal?.filter(
            (item) => {
                return item.state_id == filteredState?.id;
            }
        );

        const temp = [];
        filteredChecklist.map((item) => {
            temp.push({
                name: item.name,
                option_id: item.id,
            });
        });

        setShowableChecklist(temp);
    };

    const handleTypeChange = (e) => {
        setPropertyLegalType(e.target.value);

        const filteredPropertyLegal = legalChecklist.find((property) => {
            return property.name == e.target.value;
        });

        setSelectedLegalChecklist(filteredPropertyLegal);
        setStateName("");
        setStateId("");
        setShowableChecklist([]);
    };

    const handleAddDoc = (e) => {
        const newDoc = { name: inputValue };
        const allDoc = [...showableChecklist, newDoc];
        setShowableChecklist(allDoc);
        setInputValue("");
    };

    // document remove function
    const documentRemove = (index) => {
        const filteredDoc = showableChecklist.filter((doc, i) => {
            return i != index;
        });
        setShowableChecklist(filteredDoc);
    };

    // cancel submit from function
    const handleCancel = () => {
        const filteredChecklist = selectedLegalChecklist?.propertyLegal?.filter(
            (item) => {
                return item.state_id == stateId;
            }
        );

        const temp = [];
        filteredChecklist.map((item) => {
            temp.push({
                name: item.name,
                option_id: item.id,
            });
        });

        setShowableChecklist(temp);
        history.push("/admin/configuration");
    };

    const handleSubmit = () => {
        const sendableObject = {
            legal_type_id: selectedLegalChecklist.id,
            state_id: stateId,
            options: showableChecklist,
        };

        dispatch(configActions.addLegalChecklist(sendableObject));
    };

    return (
        <Container fluid className=" mt-5">
            <Card className="card bg-white p-4">
                <Row>
                    <Col>
                        <Form className="" onSubmit={(e) => e.preventDefault()}>
                            <h5>Type*</h5>
                            <Row>
                                <Col xs="12" md="6">
                                    <select onChange={(e) => handleTypeChange(e)} className="form-control mb-2" >
                                        {legalChecklist.map((propertyLegal) => {
                                            return (
                                                <option
                                                    selected={
                                                        propertyLegal.id == selectedLegalChecklist.id
                                                    }
                                                    key={propertyLegal.id}
                                                    value={propertyLegal.name}
                                                >
                                                    {propertyLegal.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <hr/>                 
                            <h5>County*</h5>
                            <Row>
                                <Col xs="12" md="6">
                                    <select
                                        value={stateName}
                                        onChange={(e) => handleStateChange(e)}
                                        className="form-control mb-2">
                                        <option value=''>Select County</option>
                                        {states?.map((state) => {
                                            return (
                                                <option key={state.id} value={state.name}>
                                                    {state?.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <div className="my-2">
                                {showableChecklist?.map((property, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col xs="12" md="6">
                                                <div className=" mb-2 bg-secondary px-3 py-1 rounded d-flex align-items-center justify-content-between  mb-3">
                                                    <span> {property?.name} </span>

                                                    <Button outline onClick={() => documentRemove(index)}>
                                                        <i className="fas fa-times    "></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>

                            <Row className="my-5">
                                <Col xs="12" md="6">
                                    <input
                                        disabled={stateName == ""}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        value={inputValue}
                                        className="form-control mb-2 "
                                        type="text"
                                        placeholder="Legal Document"
                                    />
                                </Col>

                                <Col xs="12" md="1" className="text-left">
                                    <Button
                                        disabled={stateName == "" || inputValue.trim().length == 0}
                                        onClick={handleAddDoc}
                                        className="btn  btn-lg btn-success d-flex justify-content-center align-items-center"
                                    >
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </Col>
                            </Row>

                            <Card className="mt-5 bg-white border-0">
                                <Row>
                                    <Col className="text-right ">
                                        <Button onClick={handleCancel} className="rz-button-outline-primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={showableChecklist?.length == 0}
                                            onClick={handleSubmit}
                                            className="rz-button-primary ml-3"
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
};

export default AddEditLegalChecklist;
