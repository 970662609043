import Paginate from "components/Paginate";
import { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card, Container, Spinner, Table, Badge } from 'reactstrap';
import { settingActions } from "redux/actions/setting.actions";
import { percentageActions } from "../../redux/actions/percentage.actions";
import { truncate } from "utils";
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { CheckPermission } from "utils";
import { PERMISSIONS } from "utils";
import { RESOURCE } from "utils";
import { PROPERTY_TYPE } from "utils";
import Select from 'react-select';
import { HiOutlinePlusCircle } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const Index = () => {
    const { settings, pager, loading } = useSelector((state) => state.getSettingReducer);
    const { percentages, pager: pagesnew, loading: ploading } = useSelector((state) => state.getPercentageReducer);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenP, setIsModalOpenP] = useState(false);
    const [columnList, setColiumnList] = useState([]);
    const SettingSchema = yup.object().shape({
        name: yup.string().required(),
        // value: yup.string().required(),
    })

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(SettingSchema)
    })

    const { register: register2, handleSubmit: handleSubmit2,
        control,
        setValue: setValue2,
        reset: reset2,
        formState: { errors: errors2 } } = useForm({
            resolver: yupResolver(SettingSchema)
        })

    useEffect(() => {
        dispatch(settingActions.getSettings());
        dispatch(percentageActions.getPercentages());
    }, []);

    useEffect(() => {
        setIsModalOpen(false);
        setIsModalOpenP(false);
    }, [settings]);

    useEffect(() => {
        const uniquePropertyTypes = percentages?.reduce((accumulator, current) => {
            current?.property_types?.forEach(type => {
                if (!accumulator.find(accType => accType.value === type.value)) {
                    accumulator.push(type);
                }
            });
            return accumulator;
        }, []);
        setColiumnList(uniquePropertyTypes)
    }, [percentages]);

    const property_types = useWatch({
        control,
        name: "property_types",
    });

    const onPercentageStatusChange = (id, e) => {
        let data = {
            id,
            status: e.target.checked ? true : false,
        };
        dispatch(
            percentageActions.genericPercentages(data, "updatePercentages")
        );
    };

    const onSubmit = (data) => {
        if (data.setting_id)
            dispatch(settingActions.genericSettings(data, 'updateSettings'));
        else
            dispatch(settingActions.genericSettings(data, 'storeSettings'))
    }

    const onSubmit2 = (data) => {
        setIsModalOpenP(false);
        if (data.id)
            dispatch(percentageActions.genericPercentages(data, 'updatePercentages'));
        else
            dispatch(percentageActions.genericPercentages(data, 'storePercentages'))
    }
    const addEditModal = (settingId, check) => {
        if (check == 1) {
            setIsModalOpen(!isModalOpen)
            if (settingId == null) return reset();
            var setting = settings?.find(({ id }) => id == settingId);
            setValue('name', setting?.name)
            setValue('value', JSON.stringify(setting.value))
            setValue('setting_id', setting?.id)
        }
        else {
            setIsModalOpenP(!isModalOpenP);
            if (settingId == null) return reset2();
            var percentage = percentages?.find(({ id }) => id == settingId);
            setValue2('name', percentage?.name)
            setValue2('property_types', percentage?.property_types)
            setValue2('types', percentage?.types)
            percentage?.property_types?.forEach(item => {
                setValue2(`${item.value}`, `${item.percentage || 0}`);
            });
            setValue2('id', percentage?.id)
        }

    }

    const onDeleteSetting = (settingId, check) => {
        if (check == 1) {
            const confirm = window.confirm("Are you sure to Delete?");
            if (confirm) {
                dispatch(settingActions.genericSettings(settingId, 'deleteSettings'));
            }
        }
        else {
            const confirm = window.confirm("Are you sure to Delete?");
            if (confirm) {
                dispatch(percentageActions.genericPercentages(settingId, 'deletePercentages'));
            }
        }

    };

    return (
        <>
            <Container className="mt-2" fluid>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <button onClick={() => addEditModal(null, 1)} className="btn rz-button-primary" type="button">
                            <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add Setting
                        </button>
                    </Col>
                </Row>

                <Card className="mt-4">
                    <Table responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr className="text-center">
                                    <td colSpan="10">
                                        <Spinner animation="border" role="status" >{null}</Spinner>
                                    </td>
                                </tr>
                            ) : (
                                settings?.map((setting, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> {setting.name} </td>
                                            <td> {truncate(JSON.stringify(setting.value), 100)} </td>
                                            <td className="d-flex">
                                                <div
                                                    onClick={() => addEditModal(setting?.id, 1)}
                                                    color="primary"
                                                    className="btn-sm"
                                                >
                                                    <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>

                                                <div
                                                    onClick={() => onDeleteSetting(setting?.id, 1)}
                                                    className="ml-3"
                                                >
                                                    <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>

                    {settings?.length > 0 && pager && pager.pages.length && (
                        <Paginate path="/admin/role" pager={pager} />
                    )}
                </Card>

                <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>
                            <h1 className="rz-heading rz-text-18">Settings</h1>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`name`}>
                                    Name
                                </Label>
                                <input
                                    {...register(`name`)}
                                    className={classnames({ 'is-invalid': errors.name, 'form-control': true })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`value`}>
                                    Value
                                </Label>
                                <textarea
                                    rows='4'
                                    {...register(`value`)}
                                    className={classnames({ 'is-invalid': errors.value, 'form-control': true })}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="rz-button-outline-primary"
                                onClick={() => setIsModalOpen(!isModalOpen)}>
                                Cancel
                            </Button>
                            {' '}
                            <Button
                                className="rz-button-primary"
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
            <Container className="mt-5" fluid>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <button onClick={() => addEditModal(null, 2)} className="btn rz-button-primary" type="button">
                            <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add Fees Percentages
                        </button>
                    </Col>
                </Row>
                <Card className="mt-4">
                    <Table responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>Name</th>
                                <th>Asset Type</th>
                                {columnList?.map((d) => (
                                    <th>{d?.label}%</th>
                                ))}
                                <th>Type</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ploading ? (
                                <tr className="text-center">
                                    <td colSpan="10">
                                        <Spinner animation="border" role="status" >{null}</Spinner>
                                    </td>
                                </tr>
                            ) : (
                                percentages?.map((percentage, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> {percentage.name} </td>
                                            <td>
                                                {(percentage?.property_types)?.map((d) => (
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-primary mr-4`}
                                                    >
                                                        {d?.label}
                                                    </Badge>
                                                ))}
                                            </td>
                                            {columnList?.map(column => {
                                                const match = percentage?.property_types?.find(
                                                    type => type.value == column.value
                                                );

                                                return match
                                                    ? { ...column, percentage: match.percentage }
                                                    : { ...column, percentage: '0' };
                                            })?.map((d) => (
                                                <td>{d.percentage || 0}</td>
                                            ))}
                                            <td>
                                                <div className="d-flex">
                                                    {(percentage?.types || [])?.map((d) => (
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-primary mr-4`}
                                                        >
                                                            {d?.label}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            </td>
                                            <td >
                                                <div className="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Setting)}
                                                        checked={percentage.status == true ? true : false}
                                                        name={`property_status`}
                                                        onChange={(e) =>
                                                            onPercentageStatusChange(percentage.id, e)
                                                        }
                                                        className="custom-control-input"
                                                        id={`property-status-${percentage.id}`}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={`property-status-${percentage.id}`}
                                                    >
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-${percentage.status == "Active"
                                                                ? "success"
                                                                : "primary"
                                                                } mr-4`}
                                                        >
                                                            {percentage.status}
                                                        </Badge>
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="d-flex">
                                                <div
                                                    onClick={() => addEditModal(percentage?.id, 2)}
                                                >
                                                    <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>

                                                <div
                                                    onClick={() => onDeleteSetting(percentage?.id, 2)}
                                                    className="ml-3"
                                                >
                                                    <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                    {percentages?.length > 0 && pagesnew && pagesnew.pages.length && (
                        <Paginate path="/admin/role" pager={pagesnew} />
                    )}
                </Card>
                <Modal isOpen={isModalOpenP} centered={true} className='modal-lg'>
                    <Form onSubmit={handleSubmit2(onSubmit2)}>
                        <ModalHeader>
                            <h1 className="rz-heading rz-text-18">Percentages</h1>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`name`}>
                                    Name
                                </Label>
                                <input
                                    {...register2(`name`)}
                                    className={classnames({ 'is-invalid': errors2.name, 'form-control': true })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`Percentagestype`}>
                                    Asset Type *
                                </Label>
                                <Controller
                                    name={`property_types`}
                                    control={control}
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={[
                                                { value: `${PROPERTY_TYPE?.RESIDENTIAL}`, label: 'Residential' },
                                                { value: `${PROPERTY_TYPE?.COMMERCIAL}`, label: 'Commercial' },
                                                { value: `${PROPERTY_TYPE?.HOLIDAY_HOME}`, label: 'Holiday Home' },
                                            ]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />}
                                />
                            </FormGroup>
                            {
                                property_types?.map((pt) => (
                                    <FormGroup>
                                        <Label className='form-label' htmlFor={`${pt?.value}`}>
                                            {pt?.label} Percentage
                                        </Label>
                                        <input
                                            number
                                            rows='4'
                                            {...register2(`${pt?.value}`)}
                                            className={classnames({ 'is-invalid': errors2.pt?.value, 'form-control': true })}
                                        />
                                    </FormGroup>
                                ))
                            }
                            <FormGroup>
                                <Label className='form-label' htmlFor={`Percentagestype`}>
                                    Fees Type (Buy,Sell)
                                </Label>
                                <Controller
                                    name={`types`}
                                    control={control}
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={[
                                                { value: `BUY`, label: 'BUY' },
                                                { value: `SELL`, label: 'SELL' },
                                            ]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="rz-button-outline-primary"
                                onClick={() => setIsModalOpenP(!isModalOpenP)}>
                                Cancel
                            </Button>
                            {' '}
                            <Button
                                className="ml-3 rz-button-primary"
                            >
                                {ploading ? 'Saving...' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
        </>
    );
};

export default Index;
