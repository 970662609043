import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { PopupButton } from "react-calendly";

const Booking = () => {
    return (
        <Col lg="5" md="7">
            <Card className="bg-white shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                        <h3>Welcome to OwnMali! 👋</h3>
                        <small>Book Appoitment With Our Property Manager</small>
                        <PopupButton
                            className="btn btn-primary mt-4"
                            url={`${process.env.REACT_APP_CALENDLY_URL}`}
                            rootElement={document.getElementById("root")}
                            text="Click here to schedule!"
                        />
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default Booking;
