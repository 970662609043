import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Spinner,
  Table,
  Row,
  Col,
  Label,
} from "reactstrap";
import { moneyFormat } from "utils";
import { useGetPortfolioById } from "../../hooks/useMeQuery";

const CustomerDetails = ({ match }) => {
  const { data: customerData } = useGetPortfolioById({
    user_id: match?.params?.id
  })
  const customer = customerData?.user;
  const [activities, setActivities] = useState([])
  useEffect(() => {
    if (customerData) {
      setActivities(customerData?.activities)
    }
  }, [])
  return (
    <div>
      <Container className="mt-5" fluid>
        <Row>
          <Col className="d-flex justify-content-between">
            <h1>{customerData?.user?.name}</h1>
            {/* <div>
              <Button color="primary"> Edit </Button>
              <Button outline color="primary">
                Suspend
              </Button>
            </div> */}
          </Col>
        </Row>

        <Row className="mt-3 customer-detail-div">
          <Col xl="9" md="9" xs="12">
            <Card style={{ minHeight: "260px" }} className="p-3">
              <Row>
                <Col md="7" xs="12">
                  <div className="d-flex align-items-center">
                    <img
                      className="mx-2"
                      width="120"
                      src={customerData?.user?.avatar} alt="" />
                    <div className="pl-3">
                      <h4 className="font-weight-600 mb-0"> {customerData?.user?.name} </h4>
                      <h5 className="user-mail"> {customerData?.user?.email} </h5>
                      <p className="text-sm subhead-color">
                        <i class="fas fa-phone mr-2"></i> {customerData?.user?.phone}
                      </p>
                      <p className="text-sm subhead-color">
                        {customerData?.user?.wallet_address?.substring(0, 10)}...
                      </p>
                    </div>
                  </div>
                  <div className="d-flex jusitfy-content-between mt-3">
                    <div>
                      <h2 className="mb-0 head-color mb-n1 font-weight-400">
                        {" "}
                        {moneyFormat(customerData?.total_invested_amount)}{" "}
                      </h2>
                      <small className="subhead-color"> Total Investment </small>
                    </div>

                    <div className="pl-4">
                      <h2 className="mb-0 head-color mb-n1 font-weight-400"> {moneyFormat(customerData?.total_returns)}</h2>
                      <small className="subhead-color"> Total Gain </small>
                    </div>
                  </div>
                </Col>
                <Col md="5" xs="12" className="inner-detail">
                  <Row className="mb-2">
                    <Col>
                      <i class="fas fa-user mr-2"></i> Username
                    </Col>
                    <Col> {customerData?.user?.name} </Col>
                  </Row>

                  <Row className="my-3">
                    <Col>
                      <i class="fas fa-sign mr-2"></i> Status
                    </Col>
                    <Col> {customerData?.user?.status} </Col>
                  </Row>

                  <Row className="my-3">
                    <Col>
                      <i class="fas fa-globe mr-2"></i> Location
                    </Col>
                    <Col> {customerData?.user?.address} </Col>
                  </Row>

                  <Row className="my-3">
                    <Col>
                      <i class="fas fa-star mr-2"></i> Referral Code
                    </Col>
                    <Col> {customerData?.user?.referral_token} </Col>
                  </Row>

                  <Row className="my-3">
                    <Col>
                      <i class="fas fa-star mr-2"></i> Last Active
                    </Col>
                    <Col>
                      {new Date(customerData?.user?.updated_at).toLocaleTimeString()}
                      <br />
                      {new Date(customerData?.user?.updated_at).toDateString()}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col className="pl-xl-0" xl="3" md="3" xs="12">
            <Card style={{ minHeight: "260px" }} className="p-3">
              <Row className="my-2">
                <Col>Status</Col>
                <Col>{customerData?.user?.status}</Col>
              </Row>
              <Row className="my-2">
                <Col> Wallet Balance </Col>
                <Col>{moneyFormat(customerData?.user?.wallet_balance)}</Col>
              </Row>

              <Row className="my-2">
                <Col>e-KYC</Col>
                <Col className="text-capitalize">
                  {customerData?.user?.userPassbase?.status}
                </Col>
              </Row>

              <Row className="my-2">
                <Col>
                  <Label> User Group </Label>
                  <select className="form-control">
                    <option>Investor</option>
                    <option>Normal</option>
                    <option>Buyer</option>
                  </select>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <div className="row">
          <div className="col-lg-12 mt-4">
            <div className="d-flex align-items-center mb-4">
              {/* <img src={MenuIcon} alt="" /> */}
              <h2 className="mb-0 ml-3">Total Investments</h2>
            </div>
            <Card>
              <Table responsive >
                <thead className="thead-light">
                  <tr>
                    <th> Name </th>
                    <th> PROPERTY ID </th>
                    <th> TOTAL SQYD </th>
                    <th> INVESTED AMOUNT </th>
                    <th> AVG. GROWTH </th>
                    <th> GAINED AMOUNT </th>
                    <th className="text-right"> ACTIONS </th>
                  </tr>
                </thead>
                <tbody>
                  {customerData?.my_investments.map((item) => {
                    return (
                      <tr key={item?.id}>
                        <td> {item?.name} </td>
                        <td className="text-primary"> #{item?.id} </td>
                        <td> {item?.total_sqyds} </td>
                        <td> {moneyFormat(item?.total_investment)} </td>
                        <td > {moneyFormat(item?.total_returns)} </td>
                        <td> {moneyFormat(item?.growth_price)} </td>
                        <td className="text-right">
                          <NavLink
                            to={`/admin/order/${item?.id}`}
                            className="btn-link"
                          >
                            <i class="fas fa-eye    "></i>
                          </NavLink>

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {customerData?.my_investments.length <= 0 &&
                <Row className="my-3 px-3 text-center">
                  <Col>
                    <h3>No Investment Found.</h3>
                  </Col>
                </Row>
              }
            </Card>
          </div>

          {/* <div className="col-lg-6 mt-4">
            <Card className="p-3 user-timeline-div">
              <div className="d-flex align-items-center">
                <img src={MenuIcon} alt="" />
                <h2 className="mb-0 ml-3">User Timeline</h2>
              </div>
              {activities.length > 0 && activities.map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-1 pr-0 dots-div">
                    <div className="blue-dot dot"></div>
                    <div className="lower-line"></div>
                  </div>
                  <div className="col-11 mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="order-status mb-1">{item?.message}</p>
                      <span className="order-time">{new Date(item?.created_at).toDateString()}</span>
                    </div>
                  </div>
                </div>
              ))}

            </Card>
          </div> */}
        </div>

        <Row className="mt-4">
          <Col>
            <h2>
              Total Orders
            </h2>
            <Card>
              {/* <Row className="my-3 px-3">
                <Col>
                  <Button color="primary">Add Order</Button>
                </Col>
                <Col className="text-right">
                  <select className="form-control d-inline w-25">
                    <option>Select Status</option>
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </Col>
              </Row> */}
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th> # </th>
                    <th> STATUS </th>
                    <th> ORDER VALUE </th>
                    <th> TOTAL SQYD </th>
                    <th> CREATED ON </th>
                    <th> PROPERTY NAME </th>
                    <th> Asset ID  </th>
                    <th className="text-right"> ACTIONS </th>
                  </tr>
                </thead>

                <tbody>
                  {customerData?.orders?.map((item) => {
                    return (
                      <tr key={item?.id}>

                        <td className="text-primary">
                          <NavLink
                            to={{
                              pathname: `/admin/order/${item?.id}`,
                              state: {
                                order: item,
                                editable: false,
                              },
                            }}
                          >
                            #{item?.id}
                          </NavLink> </td>
                        <td> {item?.status} </td>
                        <td> {moneyFormat(item?.total_amountp)} </td>
                        <td> {item?.no_sqyds} </td>
                        <td>{new Date(item?.created_at).toDateString()}</td>
                        <td className="text-primary"> {item?.property_name} </td>
                        <td className="text-primary"> {item?.plot_id} </td>
                        <td className="text-right">
                          <NavLink
                            to={`/admin/order/${item?.id}`}
                            className="btn-link"
                          >
                            <i class="fas fa-eye    "></i>
                          </NavLink>
                          {/* <Button className="btn-link btn-sm bg-white">
                            <i class="fas fa-ellipsis-v    "></i>
                          </Button> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {customerData?.orders.length <= 0 &&
                <Row className="my-3 px-3 text-center">
                  <Col>
                    <h3>No Order Found.</h3>
                  </Col>
                </Row>}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerDetails;
