import Paginate from "components/Paginate";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Card, FormGroup, Label, Spinner, Table, FormText } from "reactstrap";
import BadgeImage from "../../assets/img/Badge.png"
import BadgeDelayImage from "../../assets/img/Badge-delay.png"
import NotFound from "components/NotFound/NotFound";
import { DatePicker } from 'reactstrap-date-picker'
import exportFromJSON from "export-from-json";
import { useGetCustomerFilter } from "hooks/useReport";

const CustomerList = () => {
  const intialState = {
    kyc: "",
    wallet: "",
    role: 2,
    from_date: new Date().toISOString(),
    to_date: new Date().toISOString(),
  }
  const [filtterData, setFilterData] = useState(intialState)
  const [applyFilter, setApplyFilter] = useState({});

  const { data, isLoading: loading, refetch } = useGetCustomerFilter({
    filter: applyFilter
  }, Object.keys(applyFilter)?.length > 0);

  function handleChange(value) {
    setFilterData({
      ...filtterData,
      from_date: value
    })
  }

  function handleChangeTo(value) {
    setFilterData({
      ...filtterData,
      to_date: value
    })
  }
  const exportPDF = () => {
    const title = `customer-report-${filtterData?.from_date}-${filtterData?.to_date}`;

    const report_data = data?.data?.map((customer) => {
      return ({
        "ID":`${customer?.id}`,
        "NAME": customer?.name,
        "SMILE ID KYC NAME": customer?.kyc?.video_kyc_metadata?.FullName,
        "EMAIL": customer?.email,
        "DOB": customer?.kyc?.video_kyc_metadata?.DOB,
        "PHONE": customer?.phone,
        "Occupation": customer?.kyc?.aadhar_metadata?.occupation || "NA",
        "Source of Funds": customer?.kyc?.aadhar_metadata?.source_of_fund || "NA",
        "KRA PIN": customer?.kyc?.aadhar_metadata?.kra_pin,
        "ID NUMBER": customer?.kyc?.aadhar_metadata?.id_number,
        "National ID - Front side": customer?.kyc?.pan_metadata?.frontphoto,
        "National ID - Back side": customer?.kyc?.pan_metadata?.backphoto,
        "WALLET ADDRESS":customer?.wallet_address ? customer?.wallet_address : "Not Provided",
        "LAST ACTIVITY":`${new Date(customer?.updated_at).toDateString()} + ${new Date(customer?.updated_at).toLocaleTimeString()}`,
        "INVESTED":`${customer?.total_investment_sqyds ? customer?.total_investment_sqyds : 0}`,
        "TOTAL VALUE":`${customer?.total_investment_amount ? customer?.total_investment_amount : 0}`,
        "LOCATION":customer?.country ? customer?.country : "Not Provided",
        "E-KYC":customer?.kyc?.status == "auto_approved" ? "Approved": "pending"
      })
    })
    exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
  };


  return (
    <>
      <div className="row">
        <div className='col-4'>
          <label>Select Using Mobile</label>
          <input
            onChange={(e) => setFilterData({
              ...filtterData,
              phone: e.target.value
            })}
            value={filtterData.phone}
            className='form-control'
            placeholder="Enter mobile"
            id="phone" />
        </div>
        <div className='col-4'>
          <label>Select KYC Status</label>
          <select
            value={filtterData.kyc}
            onChange={(e) => setFilterData({
              ...filtterData,
              kyc: e.target.value
            })}
            className='form-control'>
            <option selected value=''>Select Status</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
        </div>
        <div className='col-4'>
          <label>Digital wallet Status</label>
          <select
            value={filtterData.wallet}
            onChange={(e) => setFilterData({
              ...filtterData,
              wallet: e.target.value
            })}
            className='form-control'>
            <option value=''>Select Options</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6">
          <FormGroup>
            <Label>Registration - From Date</Label>
            <DatePicker
              size="md"
              id="from-date"
              value={filtterData.from_date}
              onChange={(v, f) => handleChange(v, f)} />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup>
            <Label>Registration - To Date</Label>
            <DatePicker
              id="to-date"
              value={filtterData.to_date}
              onChange={(v, f) => handleChangeTo(v, f)} />
          </FormGroup>
        </div>
      </div>
      <div className="row pb-2 pt-4">
        <div className="col-12">
          <button
            onClick={() => setApplyFilter(filtterData)}
            className="btn rz-button-primary mr-3" type="button">
            Apply Filter
          </button>
          <button
            disabled={!data?.data || data?.data?.length <= 0}
            onClick={() => exportPDF()}
            className="btn rz-button-primary mr-3" type="button">
            Downalod Report
          </button>
          <button onClick={() => {
            setFilterData(intialState)
            setApplyFilter({})
          }}
            className="btn rz-button-primary" type="button">
            Clear Filter
          </button>
        </div>
      </div>
      <Card>
        <Table responsive className="custom-table-div mt-4">
          <thead className="rz-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                CUSTOMER ID

              </th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER NAME

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  Mobile No

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  Wallet Address

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  LAST ACTIVITY

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  INVESTED

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  TOTAL VALUE

                </div>
              </th>

              <th>CURRENT LOCATION</th>
              <th>E-KYC</th>
            </tr>
          </thead>
          {loading ? (
            <tr className="text-center">
              <td colSpan="10">
                <Spinner animation="border" role="status" >{null}</Spinner>
              </td>
            </tr>
          ) : (
            <tbody>
              {data?.data?.map((customer) => {
                return (
                  <tr className="" key={customer?.id}>
                    <td>
                      <NavLink to={{
                        pathname: `/admin/customer/${customer?.id}`,
                        state: {
                          customer: customer,
                          editable: false,
                        },
                      }}
                        color="primary">
                        #{customer?.id}
                      </NavLink>
                    </td>
                    <td>
                      <NavLink to={{
                        pathname: `/admin/customer/${customer?.id}`,
                        state: {
                          customer: customer,
                          editable: false,
                        },
                      }}
                        color="primary">
                        <div className="d-flex align-items-center">
                          <img
                            className="mx-2"
                            width="30"
                            src={customer?.avatar}
                            alt=""
                          />
                          <div className="d-flex flex-column">
                            <span className="customer-name">{customer?.name}</span>
                            <span className="customer-mail">{customer?.email}</span>
                          </div>
                        </div>
                      </NavLink>
                    </td>
                    <td>
                      {customer?.phone}
                    </td>
                    <td>
                      {customer?.wallet_address ? customer?.wallet_address : "Not Provided"}
                    </td>
                    <td>
                      {new Date(customer?.updated_at).toDateString()} <br />
                      {new Date(customer?.updated_at).toLocaleTimeString()}
                    </td>
                    <td> {customer?.total_investment_sqyds ? customer?.total_investment_sqyds : 0} </td>
                    <td> {customer?.total_investment_amount ? customer?.total_investment_amount : 0} </td>{/* + <span className="extra-amt">1001</span> */}

                    <td>{customer?.country ? customer?.country : "Not Provided"}</td>

                    <td className="text-capitalize">
                      {customer?.kyc?.status == "auto_approved"
                        ? <img src={BadgeImage} alt="" />
                        : <img src={BadgeDelayImage} alt="" />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        {data?.data?.length <= 0 &&
          <NotFound
            message="Please Refine Your Filter"
          />
        }
        {/* {data?.data?.length > 0 && data?.pager && data?.pager.pages.length && (
          <Paginate path="/admin/customer" pager={data?.pager}
          />
        )} */}
      </Card>
    </>
  );
};

export default CustomerList;
