import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { useGetCompanies } from "../../../hooks/useCompany";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { propertyConstants } from "redux/constants";
import { propertyActions } from "redux/actions";
import { useMutatePropertyCompany } from "hooks/useProperty";
import { toast } from "react-toastify";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

const CompanySelect = ({ stepper, parentStepper }) => {
    const { register, getValues } = useForm({
    });
    const { data: companyList } = useGetCompanies();
    const { mutate: updateCompany } = useMutatePropertyCompany()
    const dispatch = useDispatch()
    const { property_id } = useParams();
    const { property } = useSelector(state => state.propertyReducer);
    const onSubmit = async () => {
        let data = {
            company_id: getValues("llp_company"),
            property_id: property_id
        }
        await updateCompany(data, {
            onSuccess: (res) => {
                toast.success('Company Updated Successfully')
                stepper.next()
            }
        })
    };
    useEffect(() => {
        if (property_id) getProperty(property_id)
        else dispatch({ type: propertyConstants.PROPERTY_EMPTY })
    }, []);
    const getProperty = async (id) => {
        dispatch(propertyActions.getProperty(id))
    }
    return (
        <>
            <Container fluid className="">
                <div className='content-header'>
                    <h3 className='mb-0 rz-heading rz-text-18'>Select Company</h3>
                </div>
                <Row>
                    {property?.LLPCompany?.id != undefined &&
                        property?.LLPCompany?.id != 0 ?
                        <>
                            <Col md="12" xs="12">
                                <Label>Current Company for this Asset: {property?.LLPCompany?.name}</Label>
                            </Col>
                            {/* <Col md="12" xs="12">
                                <Link
                                    onClick={() => {
                                        localStorage.setItem("company_id", property?.LLPCompany?.id)
                                    }}
                                    className="btn btn-primary" to="/admin/listofproperty">
                                    View Company
                                </Link>
                            </Col> */}
                        </>
                        : null}
                    <Col className="my-3 mb-5" md="12" xs="12">
                        <Label>Select Company for Property</Label>
                        <select className="form-control"
                            {...register("llp_company")}>
                            {companyList && companyList?.length > 0 ?
                                companyList?.map((prop) => (
                                    <option key={prop?.id}
                                        value={prop?.id}>{prop?.name}</option>
                                )) :
                                null
                            }
                        </select>
                        <Button type='button'
                            className="mt-3 rz-button-primary"
                            disabled={property?.sto_erc20_address && property?.sto_sale_address}
                            onClick={() => onSubmit()}  >
                            Submit
                        </Button>
                    </Col>

                </Row>
                <div className="d-flex align-items-center justify-content-between mt-5">
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                    <div className='d-flex align-items-center justify-content-end'>
                        <Link to='/admin/property'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button className='rz-button-outline-primary ml-3 mr-2' onClick={() => parentStepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>

                        <Button type='button' onClick={() => stepper.next()} className='rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>

                        </Button>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default CompanySelect;
