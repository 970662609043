import { Fragment, useEffect, useState } from "react";
import classnames from "classnames";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Form,
  Input,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardText,
  Nav,
  NavLink,
} from "reactstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import { HiOutlinePlusCircle } from "react-icons/hi";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import { generateArrayOfYears } from "utils";
import { moneyFormat } from "utils";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import classNames from "classnames";

const InvestmentDetails = ({ parentStepper, stepper, type }) => {
  const dispatch = useDispatch();
  const property = useSelector((state) => state.propertyReducer.property);

  // const pastYears = [];

  // const months = moment.monthsShort();
  // const [tabYears, setTabYear] = useState([]);
  // useEffect(() => {
  //   if (
  //     property?.stage == "Under Construction" &&
  //     property?.propertyPlot?.length > 0
  //   ) {
  //     const propertyPlot = property.propertyPlot[0];
  //     setTabYear([
  //       Number(propertyPlot?.extra_data?.start_year),
  //       Number(propertyPlot?.extra_data?.end_year),
  //     ]);
  //   } else {
  //     setTabYear(generateArrayOfYears());
  //   }
  // }, [property?.stage]);
  // const currYear = new Date().getFullYear();
  // Array.from(new Array(6), (v, i) => pastYears.unshift(currYear - i));

  // const otherInvestment = [
  //   { label: "Mutual Funds (%)", name: "mutual_fund_percent" },
  //   { label: "FD (%)", name: "fixed_deposit_percent" },
  //   { label: "Money Market (%)", name: "share_market_percent" },
  // ];

  // const [activeTab, setActiveTab] = useState(null);
  // const [propertyPerformance, setPropertyPerformance] = useState([]);

  // const GrowthSchema = yup.object().shape({
  //   growth_percent_rate: yup.string().required(),
  // });


  // const {
  //   register,
  //   handleSubmit,
  //   trigger,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(GrowthSchema),
  // });

  // const onSubmit = (data) => {
  //   trigger();
  //   if (Object.keys(errors).length === 0) {
  //     data.year = tabYears[activeTab];
  //     data.property_id = property.id;
  //     dispatch(propertyActions.saveAboutThreeGrowth(data));
  //   }
  // };

  // get performance for selected year
  // function getSelectedYearPerformance(year) {
  //   if (property?.propertyPerformance != undefined) {
  //     var pp = property?.propertyPerformance?.find((o) => o.year == year);
  //     if (pp) {
  //       setPropertyPerformance(pp);
  //     } else {
  //       setPropertyPerformance([]);
  //     }
  //   }
  // }
  // get performance growth for selected year for each month
  // function getSelectedPerformanceGrowth(month) {
  //   if (propertyPerformance && propertyPerformance.propertyGrowth)
  //     return propertyPerformance.propertyGrowth.find((o) => o.month == month);
  //   return false;
  // }
  // const getYearTabs = () => {
  //   return tabYears.map((v, i) => (
  //     <NavItem key={i}>
  //       <NavLink
  //         href="#"
  //         style={{ fontSize: "16px" }}
  //         className={
  //           activeTab == i ? "active-tab px-4 " : "text-dark shadow-none  px-4"
  //         }
  //         onClick={() => setActiveTab(i)}
  //       >
  //         {v}
  //       </NavLink>
  //     </NavItem>
  //   ));
  // };
  // const getYearCard = (tabId) => {
  //   let year = tabYears[tabId];
  //   return (
  //     <div>
  //       <Row>
  //         <Col className="d-flex justify-content-between align-items-center">
  //           <div className="d-flex align-items-center">
  //             <div className="dollor-icon">
  //               <BiDollar />
  //             </div>
  //             <div className="ml-3">
  //               <h3 className="m-0">
  //                 {parseFloat(
  //                   propertyPerformance?.annual_growth_percent || 0
  //                 ).toFixed(2)}{" "}
  //                 %
  //               </h3>
  //               <small className="text-muted">
  //                 Property Average Annual Growth Rate
  //               </small>
  //             </div>
  //           </div>
  //           {property && <div></div>}
  //           {/* {currYear != year && (
  //                           <Form onSubmit={handleSubmit(onSubmit)}>
  //                               <div className='d-flex align-items-center justify-content-end'>
  //                                   <div>
  //                                       <div className='d-flex align-items-center justify-content-end'>
  //                                           <small className='text-muted w-25'>Add additional growth of {year}</small>
  //                                           <div className="input-group w-25">
  //                                               <div className="input-group-prepend">
  //                                                   <span className="input-group-text bg-secondary" id="basic-addon1">%</span>
  //                                               </div>
  //                                               <input
  //                                                   id={`growth_percent_rate`}
  //                                                   {...register(`growth_percent_rate`)}
  //                                                   className={classnames({ 'is-invalid': errors.growth_percent_rate, 'form-control px-2': true })}
  //                                               />
  //                                           </div>
  //                                           <button className="btn btn-link ml-2 w-25" href="#" role="button" type='submit'>Update</button>
  //                                       </div>
  //                                   </div>

  //                               </div>
  //                           </Form>
  //                       )} */}
  //         </Col>
  //       </Row>
  //       {/* <Row className='mt-5'>
  //                   {
  //                       months.map((month, i) => {
  //                           return (
  //                               <FormGroup tag={Col} md='2' key={i}>
  //                                   <Label className='form-label text-dark text-sm' htmlFor={`sqyds_start_price`}>
  //                                       {month} {year}
  //                                   </Label>
  //                                   <div className="input-group mb-3">
  //                                       <div className="input-group-prepend">
  //                                           <span className="input-group-text bg-secondary" id="basic-addon1">KSE</span>
  //                                       </div>
  //                                       <input
  //                                           disabled={true}
  //                                           value={getSelectedPerformanceGrowth(month)?.price || ''}
  //                                           className={`form-control px-2`}
  //                                       />
  //                                   </div>
  //                               </FormGroup>
  //                           )
  //                       })
  //                   }
  //               </Row> */}
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   tabYears.map((year, i) => {
  //     if (currYear == year && activeTab == null) setActiveTab(i);
  //   });
  // }, []);

  // useEffect(() => {
  //   setValue("growth_percent_rate", propertyPerformance?.growth_percent_rate);
  // }, [propertyPerformance]);

  const PerformanceSchema = yup.object().shape({
    risk_analysis: yup.string().required(),
    investment_performance_description: yup.string(),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    trigger: trigger2,
    setValue: setValue2,
    getValues: getValues2,
    formState: { errors: errors2 },
    control
  } = useForm({
    resolver: yupResolver(PerformanceSchema),
  });

  const {
    fields: yearsFields,
    append: appendYear,
    remove: removeYear,
  } = useFieldArray({
    control,
    name: "years",
  });

  const onSubmitPerformance = () => {
    trigger2();
    let data = {
      // performance: getValues2("performance"),
      risk_analysis: getValues2("risk_analysis"),
      investment_performance_description: getValues2("investment_performance_description"),
      years: getValues2("years"),
    }
    if (property?.propertyPerformance && Object.keys(errors2).length === 0) {
      // if (property?.stage == "Under Construction") {
      //   data.performance_year = tabYears;
      // } else {
      //   data.performance_year = generateArrayOfYears();
      // }
      data.property_id = property.id;
      dispatch(propertyActions.saveAboutThree(data, stepper, parentStepper, property));
    }
  };
  useEffect(() => {
    if (property) {
      // set value on property growth fields for selected year
      // getSelectedYearPerformance(tabYears[activeTab]);

      // set value for property performance for all years
      // otherInvestment.map((obj, i) => {
      //   tabYears.map((v, j) => {
      //     if (property?.propertyPerformance != undefined) {
      //       var pp = property?.propertyPerformance?.find((o) => o.year == v);
      //       if (pp) setValue2(`performance.${obj.name}[${j}]`, pp[obj.name]);
      //     }
      //   });
      // });
      setValue2("min_maturity", property.min_maturity);
      setValue2("risk_analysis", property.risk_analysis);
      setValue2(
        "investment_performance_description",
        property.investment_performance_description
      );
      if (property?.propertyPerformance?.length > 0) {
        setValue2("years", []);
        for (let i in property.propertyPerformance) {
          appendYear({
            _id: property.propertyPerformance[i]?.id,
            year: property.propertyPerformance[i]?.year,
            month: property.propertyPerformance[i]?.month,
            irr: property.propertyPerformance[i]?.target_irr,
            share_market_percent:
              property?.propertyPerformance[i]?.share_market_percent,
            mutual_fund_percent:
              property?.propertyPerformance[i]?.mutual_fund_percent,
            fixed_deposit_percent:
              property?.propertyPerformance[i]?.fixed_deposit_percent,
            metadata:
              property?.propertyPerformance[i]?.metadata,
          });
        }
      } else {
        appendYear({});
      }
    }
  }, [property]);


  return (
    <Fragment>
      <div className="content-header">
        <h3 className="mb-0 rz-text-secondary Montserrat-500">
          Investment Performance
        </h3>
      </div>

      {/* Investment Growth Starts */}
      {/* <Row>
                <Col lg='4'>
                    <div className='d-flex align-items-center'>
                        <span className='icon-round bg-gradient-success text-white'>$</span>
                        <div className='ml-3'>
                            <h3 className='m-0'>{property?.current_year_growth_percent?.toFixed(2)} %</h3>
                            <small className='text-muted'>Annual Growth of {currYear}</small>
                        </div>
                    </div>
                    <p className='text-muted text-sm w-75'>Property’s annual growth is derived from all Assets average growth percentage</p>
                </Col>
                <Col lg='4'>
                    <div className='d-flex align-items-center'>
                        <span className='icon-round bg-gradient-success text-white'>$</span>
                        <div className='ml-3'>
                            <h3 className='m-0'>{moneyFormat(property?.min_investment)}</h3>
                            <small className='text-muted'>Per sqyd</small>
                        </div>
                    </div>
                    <p className='text-muted text-sm w-75'>Minimum investment is the least unit’s cost of the entire property ({moment().format('YYYY-MMM')})</p>
                </Col>
                <Col lg='4'>
                    <div className='d-flex align-items-center'>
                        <span className='icon-round bg-gradient-success text-white'>$</span>
                        <div className='ml-3'>
                            <h3 className='m-0'>{moneyFormat(property?.total_property_value)}</h3>
                            <small className='text-muted'>Total Assets price</small>
                        </div>
                    </div>
                    <p className='text-muted text-sm w-75'>Total cost of the entire property for {moment().format('YYYY-MMM')}</p>
                </Col>
            </Row> */}
      {/* {property?.share_type != "NCD" &&
        property?.stage != "Under Construction" && (
          <Row>
            <Col>
              <div className="card text-white bg-secondary">
                <div className="card-body rz-shadow-none">
                  <h4 className="card-title rz-text-primary Montserrat-600">
                    Asset Overall Avrage Growth
                  </h4>
                  <div className="card shadow-sm">
                    <div className="card-header d-flex justify-content-start">
                      <Nav pills>{getYearTabs()}</Nav>
                    </div>
                    <div className="card-body rz-shadow-none">
                      <TabContent activeTab={`${activeTab}`}>
                        <TabPane tabId={`${activeTab}`}>
                          {getYearCard(activeTab)}
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )} */}
      {/* Investment Performance Starts */}
      <Form>
        <Row className="my-4">
          <FormGroup tag={Col} md="6">
            <Label>
              {property?.share_type == "NCD" ? "Tenure" : "Minimum maturity"}{" "}
              <span className="required-star">*</span>
            </Label>
            <br />
            <input
              className="form-control mb-3"
              type="text"
              placeholder="eg. 1"
              {...register2(`min_maturity`)}
            />
          </FormGroup>
          <FormGroup tag={Col} md="6">
            <Label className="form-label" htmlFor={`property_type`}>
              Investment Risk Analysis <span className="required-star">*</span>
            </Label>
            <select
              className={classnames({
                "is-invalid": errors2.risk_analysis,
                "form-control": true,
              })}
              {...register2(`risk_analysis`)}
              id="risk_analysis"
            >
              <option value="">Select Risk Type</option>
              <option>Very Low</option>
              <option>Low</option>
              <option>Moderate</option>
              <option>Medium</option>
              <option>High</option>
              <option>Very High</option>
            </select>
          </FormGroup>
        </Row>
        <Row className="my-4">
          <FormGroup tag={Col} md="12">
            <Label htmlFor="about-property">
              Investment Performance Description{" "}
              <span className="required-star">*</span>
            </Label>
            <textarea
              className={classnames({
                "is-invalid": errors2.investment_performance_description,
                "form-control": true,
              })}
              {...register2(`investment_performance_description`)}
              id="about-property"
              rows="4"
              placeholder="Enter Description"
            />
          </FormGroup>
        </Row>
        <Row>
          {/* <Col>
            <div className="card text-white bg-secondary">
              <div className="card-body rz-shadow-none">
                <h4 className="card-title  Montserrat-500">
                  Investment Performance
                </h4>
                <div className="card shadow-sm">
                  <div className="card-body rz-shadow-none">
                    <Row>
                      <Col></Col>
                      {tabYears.map((v, i) => {
                        return (
                          <Col key={i}>
                            <h3
                              className={`text-center  rz-text-primary ${
                                v == currYear
                                  ? "Montserrat-500"
                                  : " Montserrat-400"
                              }`}
                            >
                              {v}
                            </h3>
                          </Col>
                        );
                      })}
                    </Row>
                    {otherInvestment.map((obj, i) => {
                      return (
                        <Row className="d-flex align-items-center mb-3" key={i}>
                          <Col>
                            <h3
                              className="rz-text-primary Montserrat-400"
                              style={{ fontSize: "14px" }}
                            >
                              {obj.label}
                            </h3>
                          </Col>
                          {tabYears.map((w, j) => {
                            return (
                              <Col key={j}>
                                <input
                                  {...register2(
                                    `performance.${obj.name}[${j}]`
                                  )}
                                  className={classnames({
                                    "is-invalid": errors2.performance,
                                    "form-control": true,
                                  })}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
          <Col sm={12}>
            {yearsFields.map((field, index) => (
              <Row
                style={{ position: "relative" }}
                className="mt-5 px-2 pb-2 pt-3 year-wrapper"
                key={index}
              >
                <div
                  onClick={() => removeYear(index)}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 100,
                    cursor: "pointer",
                    color: "#82868B"
                  }}
                >
                  <FaRegTrashAlt style={{ cursor: "pointer" }} />
                </div>
                <Col className="mb-1" sm={12}>
                  <h1 className="rz-heading rz-text-18">
                    Year {index + 1}
                  </h1>
                </Col>
                <FormGroup tag={Col} md="6">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Year
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(`years.${index}.year`, {
                      required: true,
                    })}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]?.year,
                      "form-control": true,
                    })}
                    placeholder="Enter Year"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="6">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Month
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(`years.${index}.month`, {
                      required: true,
                    })}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]?.month,
                      "form-control": true,
                    })}
                    placeholder="Enter Month"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Asset Est.IRR %
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(`years.${index}.irr`, {
                      required: true,
                    })}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]?.irr,
                      "form-control": true,
                    })}
                    placeholder="Enter Growth %"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    MF Est.Growth %
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}.mutual_fund_percent`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.mutual_fund_percent,
                      "form-control": true,
                    })}
                    placeholder="Enter Growth %"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    FD Est.Gw %
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}.fixed_deposit_percent`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.fixed_deposit_percent,
                      "form-control": true,
                    })}
                    placeholder="Enter Growth %"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Money Market Est.Growth %
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}.share_market_percent`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.share_market_percent,
                      "form-control": true,
                    })}
                    placeholder="Enter Growth %"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Asset Est.Value
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(`years.${index}.metadata.asset_value`, {
                      required: true,
                    })}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]?.metadata?.asset_value,
                      "form-control": true,
                    })}
                    placeholder="Enter Est.Value"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    MF Est.Value
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}.metadata.mutual_fund_value`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.metadata?.mutual_fund_value,
                      "form-control": true,
                    })}
                    placeholder="Enter Est.Value"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    FD Est.Value
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}.metadata.fixed_deposit_value`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.metadata?.fixed_deposit_value,
                      "form-control": true,
                    })}
                    placeholder="Enter Est.Value"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
                <FormGroup tag={Col} md="3">
                  <Label
                    className="form-label"
                    style={{ fontSize: 14 }}
                  >
                    Money Market Est.Value
                    <span className="required-star">*</span>
                  </Label>
                  <input
                    {...register2(
                      `years.${index}..metadata.share_market_value`,
                      {
                        required: true,
                      }
                    )}
                    className={classNames({
                      "is-invalid":
                        errors2?.years?.[index]
                          ?.metadata?.share_market_value,
                      "form-control": true,
                    })}
                    placeholder="Enter Est.Value"
                    defaultValue={field?.value || ""}
                  />
                </FormGroup>
              </Row>
            ))}
          </Col>
          <Col
            className="d-flex mt-4 justify-content-center align-items-center"
            sm={12}
          >
            <div>
              <HiOutlinePlusCircle
                onClick={() => appendYear({})}
                style={{
                  fontSize: "25px",
                  color: "#7367F0",
                  cursor: "pointer",
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="mt-5 align-item-center d-flex justify-content-between">
          <div>
            <Button
              type="button"
              color="success"
              onClick={() => parentStepper.next()}
              className="btn-next"
            >
              <span className="align-middle d-sm-inline-block d-none">
                Skip
              </span>
              <i className="fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0"></i>
            </Button>
          </div>
          <div className="d-flex align-item-center justify-content-end">
            <Link to="/admin/property">
              <Button type="button" className="rz-button-outline-primary">
                Cancel
              </Button>
            </Link>
            <Button
              type="button"
              className="btn-prev ml-3 mr-2 d-flex rz-button-outline-primary"
              onClick={() => stepper.previous()}
            >
              <BsArrowLeftShort style={{ fontSize: "22px" }} />
              <span className="align-middle d-sm-inline-block d-none">
                Back
              </span>
            </Button>
            <Button
              onClick={() => onSubmitPerformance()}
              className="btn-next rz-button-primary">
              <BsArrowRightShort style={{ fontSize: "22px" }} />
              <span className="align-middle d-sm-inline-block d-none">
                Next
              </span>
            </Button>
          </div>
        </div>
      </Form>
    </Fragment>
  );
};

export default InvestmentDetails;
