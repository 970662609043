import NotFound from "components/NotFound/NotFound";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Badge, Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { employeeActions } from "redux/actions";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
const EmployeeList = (props) => {
  const { employee, loading } = useSelector(
    (state) => state.getEmployeeReducer
  );
  const dispatch = useDispatch();
  const hanldeEmployeeDelete = (id) => {
    const confirm = window.confirm("Are You sure? ");
    if (confirm) {
      dispatch(employeeActions.deleteEmployees(id));
    }
  };

  useEffect(() => {
    dispatch(employeeActions.getEmployees(`?is_employee=true`));
  }, []);

  return (
    <>
      <Container fluid className="mt-2">
        <Row className="my-2 align-items-center">
          <Col>
            <h3 className="rz-heading rz-text-20">EMPLOYEE LIST</h3>
          </Col>
          <Col className="text-right">
            <NavLink
              to={{
                pathname: `/admin/employee/add`,
                state: {
                  employee: {},
                },
              }}
              className="btn rz-button-primary"
            >
              <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add New Employee
            </NavLink>
          </Col>
        </Row>
        <Card className="mt-5">
          <Table responsive>
            <thead className="rz-thead-style">
              <tr>
                <th>EMPLOYEE ID</th>
                <th>EMPLOYEE NAME</th>
                <th>EMPLOYEE EMIAL</th>
                <th>ROLE</th>
                <th>CONTACT NUMBER</th>
                <th className="text-center">STATUS</th>
                <th className="text-right">ACTIONS</th>
              </tr>
            </thead>

            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              <tbody>
                {employee?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td> {item?.id} </td>
                      <td>
                        <img width="30"
                          style={{ borderRadius: 15 }}
                          src={item?.avatar} alt="" />{" "}
                        {item?.name}
                      </td>
                      <td> {item?.role_data?.name} </td>
                      <td> {item?.phone} </td>
                      <td> {item?.email} </td>
                      <td className="text-center">
                        <Badge
                          color=""
                          className={`badge badge-pill badge-${item?.status == "Active"
                            ? "success"
                            : "danger"
                            }`}
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="text-right ">
                        <div className="align-center justify-content-end">
                          <NavLink
                            to={{
                              pathname: `/admin/employee/${item?.id}/edit`,
                              state: {
                                employee: item,
                              },
                            }}
                          // state={{ employee: item }}

                          >
                            <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                          </NavLink>
                          <div
                            onClick={() => hanldeEmployeeDelete(item?.id)}
                            className="ml-3"
                          >
                            <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {employee?.length <= 0 &&
            <NotFound
              message="Create your first employee."
            />
          }
        </Card>
      </Container>
    </>
  );
};

export default EmployeeList;
