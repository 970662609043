import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import { configActions } from "redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { PROPERTY_TYPE_VALUE } from "utils";
import NotFound from "components/NotFound/NotFound";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const featureSchema = yup.object().shape({
    feature: yup.string().required(),
    description: yup.string().required(),
    image: yup.mixed().required(),
});

const Amenities = () => {
    const dispatch = useDispatch();
    const { amenity, loading } = useSelector((state) => state.getAmenityReducer);
    const [amenityTypeId, setAmenityTypeId] = useState("3");
    const [updateAble, setUpdateAble] = useState(true);
    const [updateableId, setUpdateableId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(featureSchema) });

    const handleAddFeature = () => {
        setIsModalOpen(true);
        setUpdateAble(false);
        reset();
    };

    const handleDelete = (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            dispatch(configActions.deleteAmenitiesByPropertyType(id, amenityTypeId));
        }
    };

    const handleUpdate = (amenity) => {
        setIsModalOpen(true);
        setUpdateAble(true);
        setValue("feature", amenity.name);
        setValue("description", amenity.description);
        setUpdateableId(amenity?.id);
    };

    const handleAmintyChange = (e) => {
        setAmenityTypeId(e.target.value);
        dispatch(configActions.getAmenitiesByPropertyType(e.target.value));
    };
    useEffect(() => {
        dispatch(configActions.getAmenitiesByPropertyType(amenityTypeId));
    }, []);
    useEffect(() => {
        if (isModalOpen == false) {
            dispatch(configActions.getAmenitiesByPropertyType(amenityTypeId));
        }
    }, [isModalOpen])
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("property_type_id", amenityTypeId);
        formData.append("name", data.feature);
        formData.append("description", data.description);
        formData.append("icon", data?.image[0]);

        if (!updateAble) {
            if (data?.image[0]?.type !== "image/png") {
                toast.error("PNG image Allowed only");
                return;
            }
        }
        if (updateAble) {
            dispatch(
                configActions.updateAmenitiesByPropertyType(
                    updateableId,
                    formData,
                    amenityTypeId
                )
            );
        } else {
            dispatch(
                configActions.postAmenitiesByPropertyType(formData, amenityTypeId)
            );
        }
        reset();
        setIsModalOpen(false);
    };

    return (
        <>
            <Container fluid className="mt-2 px-md-5 px-1 ">
                <h2 className="rz-heading rz-text-20">Add New Amenities </h2>
                <Card className="card bg-white p-4  ">
                    <label className="form-label">Type* </label>
                    <Row>
                        <Col xs="12" md="7">
                            <select onChange={handleAmintyChange} className="form-control">
                                <option value={`${PROPERTY_TYPE_VALUE?.COMMERCIAL}`}>Commercial</option>
                                <option value={`${PROPERTY_TYPE_VALUE?.RESIDENTIAL}`}>Residential</option>
                                <option value={`${PROPERTY_TYPE_VALUE?.HOLIDAY_HOME}`}>Holiday Home</option>
                            </select>
                        </Col>
                        <Col>
                            <Button
                                onClick={handleAddFeature}
                                className="rz-button-primary"
                            >
                                <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add New Feature
                            </Button>
                        </Col>
                    </Row>
                    <hr />

                    {loading ? (
                        <Row>
                            <Col md="7" xs="12" className="text-center">
                                <div colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            {amenity?.map((signleAmenity) => {
                                return (
                                    <Col md="7" xs="12" className="my-3">
                                        <Row>
                                            <Col xs="1" md="1">
                                                <img width="30" src={signleAmenity?.icon} alt="" />
                                            </Col>
                                            <Col xs="6" md="7">
                                                {signleAmenity?.name}
                                            </Col>
                                            <Col className="d-flex justify-content-end" xs="4" md="4">
                                                <div
                                                    onClick={() => handleUpdate(signleAmenity)}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                >
                                                    <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                                <div
                                                    className="ml-3"
                                                    onClick={() => handleDelete(signleAmenity?.id)}
                                                >
                                                    <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                    {amenity?.length <= 0 &&
                        <NotFound
                            message="Add first Amenities for Selected Asset Type."
                        />
                    }
                    <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="px-4 pt-4 d-flex justify-content-between">
                                <div>
                                    <h1 className="rz-heading rz-text-18" id="exampleModalLabel">
                                        Add New Feature
                                    </h1>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        className="btn-close btn-link bg-white"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        <i className="fas fa-times    "></i>
                                    </Button>
                                </div>
                            </div>
                            <ModalBody>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`question`}>
                                        Title
                                    </Label>
                                    <input
                                        className="form-control mb-3"
                                        type="text"
                                        placeholder="Feature New"
                                        {...register("feature", {})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`description`}>
                                        Description
                                    </Label>
                                    <input
                                        className="form-control mb-3"
                                        type="text"
                                        placeholder="Description for feature New"
                                        {...register("description", {})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`answer`}>
                                        Attachment <br />
                                        <small> (Allowed PNG format only) </small>
                                    </Label>

                                    <div className="custom-file">
                                        <input
                                            className="custom-file-input"
                                            id="customFile"
                                            type="file"
                                            placeholder="image"
                                            accept="image/*"
                                            {...register("image", {})}
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            Choose file
                                        </label>
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="rz-button-outline-primary" onClick={() => setIsModalOpen(!isModalOpen)}>
                                    Cancel
                                </Button>

                                <Button className="rz-button-primary ml-3">Save</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Card>
            </Container>
        </>
    );
};

export default Amenities;
