import React from 'react'
import { Button } from 'reactstrap'
import { propertyActions } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import { useMutateUpdateCompany } from 'hooks/useCompany';
import { QueryClient } from 'react-query';
import { api } from 'helpers';
export default function SPVMemo({ stepper, type, company }) {
  const [spv, setSPV] = React.useState("")
  const [attachement1, setAttachement1] = React.useState("")
  const { mutate: updateCompnay } = useMutateUpdateCompany();
  React.useEffect(() => {
    if (company) {
      setSPV(company?.spv_memo)
      setAttachement1(company?.spv_memo_link)
    }
  }, [company])
  const queryClient = new QueryClient()

  async function handleSubmit() {
    if (spv) {
      let data = {
        "spv_memo": spv,
        id: company?.id
      }
      if (attachement1) {
        const formDataNew = new FormData();
        formDataNew.append("file", attachement1)
        try {
          const res = await api.post("/file",
            formDataNew
          );
          data.spv_memo_link = res?.url
        } catch (ex) {
          console.log(ex);
          return;
        }
      }
      await updateCompnay(data, {
        onSuccess: (res) => {
          toast.success('Company Updated Successfully')
          queryClient.invalidateQueries(`user_company_${company?.id}`);
          stepper.next();
        }
      })
    } else {
      toast.error("Please fill all data")
    }
  }

  return (
    <>
      <div className='content-header'>
        <h3 className='mb-0 rz-heading rz-text-18'>SPV Memo</h3>
      </div>
      <textarea rows={10} cols={10} className='form-control' value={spv} onChange={e => setSPV(e.target.value)}>
      </textarea>
      <hr></hr>
      <div className='col-md-6 form-group'>
        <label className='form-label'>Upload SVP Memo Document </label>
        <input className='form-control' type="file" onChange={e => setAttachement1(e.target.files[0])} />
        {attachement1 && <a href={`${attachement1}`} target="_blank">View Attachement</a>}
      </div>
      <div className='mt-5 d-flex justify-content-between'>
        <Button color='primary' className='btn-prev' onClick={() => stepper.previous()}>
          <i className='fa fa-chvron-left align-middle mr-sm-25 mr-0'></i>
          <i className='fa fa-chevron-left'></i>
          <span className='align-middle d-sm-inline-block d-none'>Previous</span>
        </Button>
        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
          <span className='align-middle d-sm-inline-block d-none'>Skip</span>
          <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
        </Button>
        <Button type='button' onClick={() => handleSubmit()} color='primary' className='btn-next'>
          <span className='align-middle d-sm-inline-block d-none'>Update & Next</span>
          <i className='fa fa-chevron-right align-middle mr-sm-25 mr-0'></i>
        </Button>
      </div>
    </>
  )
}
